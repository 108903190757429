/*eslint-disable */
import React, { Fragment, useEffect, useState } from 'react'
import { Archive, FileText, Lock, MoreVertical, Trash2, User , Edit, Eye } from 'react-feather'

import { Card, CardBody, CardHeader, NavLink, Nav, NavItem, TabContent, TabPane, Button, Modal, ModalHeader, Col, ModalBody, Row, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, Input } from 'reactstrap'

import { GET_ACTIVITY_TYPES } from '../../graphql/query/query'
import { useMutation, useQuery } from '@apollo/client'
import Avatar from '../components/avatar'
import DataTable from 'react-data-table-component'
import { CREATE_ACTIVITY_TYPE, DELETE_ACTIVITY_TYPE, UPDATE_ACTIVITY_TYPE, UPDATE_ACTIVITY_TYPE_STATUS } from '../../graphql/mutation'
import { ActiveTypeStatusOnSubmit, CreateActivityOnSubmit, DeleteActivityOnSubmit, UpdateActivityOnSubmit } from '../../graphql/api-callings'
import { Controller, set, useForm } from 'react-hook-form'
import { addActivity } from '../../validation'
import { yupResolver } from '@hookform/resolvers/yup'
import Select from "react-select";

import { useNavigate } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import Swal from 'sweetalert2'
import { Chips } from "primereact/chips";
import './index.css'
export default function Activites() {

const [modal, setModal] = useState(false);
   const [active, setActive] = useState('1')
     const [edit, setEdit] = useState(false);
     const [ActivityId , setActivityId] = useState(null);
     const [option , setOption ] = useState([])
     const [page , setPage ] = useState(1)
     const [value, setValues] = useState([]);

     const navigate = useNavigate()

   const [
    createActivityType
    ,
     { loading: loading1, error: errror1, data: data1 },
   ] = useMutation(CREATE_ACTIVITY_TYPE);
   const [
     deleteActivityType,
     { loading: loading2, error: errror2, data: data2 },
   ] = useMutation(DELETE_ACTIVITY_TYPE)

   const [
     updateActivityType,
     { loading: loading3, error: errror3, data: data3 },
   ] = useMutation(UPDATE_ACTIVITY_TYPE);

    const [
      modifyActivityTypeStatus,
      { loading: loading4, error: errror4, data: data4 },
    ] = useMutation(UPDATE_ACTIVITY_TYPE_STATUS);

  const toggleTab = tab => {
    if (active !== tab) {
      setActive(tab)

    }
  }

  const {
    control,
    setValue,
    setError,
    handleSubmit,
    reset,
    formState: { errors  },
  } = useForm({
    resolver: yupResolver(addActivity),
  });



  const toggle = () => setModal(!modal);

const handleDeleteActivity = (id) => {
 // console.log(id,'id')
 const payload = {
   activityTypeId: parseInt(id),
 };
  DeleteActivityOnSubmit(payload, deleteActivityType, refetch);
}
 const handleEditActivity = (row) => {
   setModal(true);
   setEdit(true);
   reset({
     activityType: row?.name || "",
     type: row?.type
       ? { label: row?.type, value: row?.type }
       : { label: "Gym", value: "Gym" }, // Ensure default value for select
     active: !!row?.active, // Ensure boolean value
     isType: !!row?.isType, // Ensure boolean value
   });
   setActivityId(row?.id);
 }

 const handleViewActivity = (row) => {

   if (row?.isType ) {
   console.log(row?.id,'Activity Exists')
          navigate(`/category/list/${row?.id}`, {
            state: { type: "activity" },
          })
   }else{
 console.log(row?.id, "go to Catagory page")
 navigate(`/category/list/${row?.id}`, { state: { type: "noActivity" } })
   }
 }
const column = [
  {
    name: "s.no",
    sortable: true,
    minWidth: "172px",
    sortField: "role",
    selector: (row, ind) => ind + 1,
  },
  {
    name: "name",
    sortable: true,
    minWidth: "172px",
    sortField: "role",
    selector: (row) => row?.name,
  },
  {
    name: "isType",
    sortable: true,
    minWidth: "172px",
    sortField: "role",
    selector: (row) => row?.isType?.toString(),
  },
  {
    name: "Active",
    sortable: true,
    minWidth: "172px",
    sortField: "role",
    selector: (row) => (
      <>
        <label
          //onClick={() =>
          //active(row?.status, row?.id)}
          class="switch"
        >
          <input
            disabled={loading}
            type="checkbox"
            checked={row?.active}
            onChange={(e) => handleinActiveHit(row?.active, row?.id)}
          />
          <span class="slider"></span>
        </label>
      </>
      // <Badge
      //   className="text-capitalize"
      //   color={row?.status ? "light-success" : "light-secondary"}
      //   pill
      // >
      // {row?.status ? "Active" : "InActive"}
      // </Badge>
    ),
  },
  {
    name: "Type",
    sortable: true,
    minWidth: "172px",
    sortField: "role",
    selector: (row) => row?.type,
  },
  {
    name: "Actions",
    width: "300px",
    cell: (row) => (
      <div>
        {/*
          <Button
            onClick={() => handleDeleteActivity(row?.id)}
            className="btn btn-danger"
             disabled={true}
          >
            Delete
          </Button>
          */}
        <Edit
          size={17}
          onClick={() => handleEditActivity(row)}
          style={{ cursor: "pointer" }}
        />
        <Eye
          size={17}
          style={{ backgroundColor: "transparent", cursor: "pointer" }}
          onClick={() => handleViewActivity(row)}
          className="ms-2"
        >
          View
        </Eye>
      </div>
    ),
  },
];

const options = [
  { label: "Gym", value: "Gym" },
  { label: "Events", value: "Events" },
  { label: "Trainer", value: "Trainer" },
  { label: "Sports", value: "Sports" },
  { label: "Experience", value: "Experience" },
]

const {
       loading,
       error,
       data,
       refetch
   } = useQuery( GET_ACTIVITY_TYPES ,{
 variables:{
  input: {
    limit: 10,
    pageStart:page - 1 ,
    name: null,
    type: null
  }
}
   })

  // console.log(errors, "what is this");

const handleinActiveHit = (status, sid) => {
  Swal.fire({
    title: "Are you sure?",
    text: status
      ? "You want to deActivate this Type!"
      : "You want to Activate this Type!",

    showCancelButton: true,
    confirmButtonColor: status ? "#d33" : "#22bb33",
    cancelButtonColor: "#d33",
    cancelButtonMarginleft: "10px",
    confirmButtonText: status ? "DeActivate" : "Activate",
  }).then((result) => {
    if (result?.isConfirmed) {
      console.log(status, "status");
      const payload = {
        activityTypeId: parseInt(sid),
      };
      ActiveTypeStatusOnSubmit(payload, modifyActivityTypeStatus, refetch);

      console.log(status, "status2");
    }
  });
};


  const CustomPagination = () => (
    <ReactPaginate
      nextLabel=""
      breakLabel="..."
      previousLabel=""
      pageRangeDisplayed={2}
      forcePage={page - 1}
      marginPagesDisplayed={2}
      activeClassName="active"
      pageClassName="page-item"
      breakClassName="page-item"
      nextLinkClassName="page-link"
      pageLinkClassName="page-link"
      breakLinkClassName="page-link"
      previousLinkClassName="page-link"
      nextClassName="page-item next-item"
      previousClassName="page-item prev-item"
      pageCount={Number(
        Math.ceil(
             data?.getAllActivityTypesList?.total / 10
        )
      )}
      onPageChange={(page) => {
        setPage(page?.selected + 1);
      }}
      containerClassName="pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-1"
    />
  );



   const handleCreateActivity = ( data ) => {

    if( edit ){
      const payload = {
        activityType: data?.activityType,
        activityTypeId: parseInt(ActivityId),
        isType: data?.isType,
        type: data?.type?.value,
      };


//console.log(payload, "what is the data");

      UpdateActivityOnSubmit(payload, updateActivityType , refetch, setModal);

    } else{
       console.log( data, "what is the data");
       const payload = {
         active: data?.active,
         activityType: data?.activityType,
         isType: data?.isType,
         type: data?.type?.value,
       };
     CreateActivityOnSubmit(payload, createActivityType, refetch, setModal);
    }

    }


   const formattedOptions = data?.getAllActivityTypes?.map((item) => ({
     value: item?.id,
     label: item?.name,
   }))

  return (
    <>
      <Card className="overflow-hidden">
        <Row>
          <Col md={6}>
            <h4 className="mx-2 mt-2"> Categories</h4>
          </Col>

          <Col md={6}>
            <div className="px-2 pt-2 d-flex justify-content-end align-items-center">
              <button
                onClick={() => {
                  toggle();
                  setEdit(false);
                  reset({
                    activityType: "",
                    type: { label: "Gym", value: "Gym" },
                    active: false, // Ensure boolean value
                    isType: false, // Ensure boolean value
                  });
                  setActivityId(null);
                }}
                className="mx-2 mt-1 btn btn-primary"
              >
                Add New Category
              </button>
            </div>
          </Col>
        </Row>

        <div className="react-dataTable">
          <DataTable
            subHeader
            columns={column}
            data={data?.getAllActivityTypesList?.activity}
            highlightOnHover
            pagination
            //paginationServer
            //paginationTotalRows={100}
            paginationPerPage={10}
            // paginationComponentOptions={{
            //     noRowsPerPage: true
            // }}
            // onChangePage={page => setPage(page)}
            paginationComponent={CustomPagination}
          />
        </div>
        {/* <Chips value={value}  onChange={(e) => setValues(e.value)} /> */}
      </Card>

      {/* <Fragment>
      <Nav pills className='mb-2'>

        <NavItem>
          <NavLink active={active === '1'} onClick={() => toggleTab('1')}>

            <span className='fw-bold'>ALL</span>
          </NavLink>
        </NavItem>
{  data &&m\

 data?.getAllActivityTypes?.map((item,index)=> {
 return(
  <>
       <NavItem>
          <NavLink active={active === `${item?.name}${index}`} onClick={() => toggleTab(`${item?.name}${index}`)}>

            <span className='fw-bold'>{item?.name}</span>
          </NavLink>
        </NavItem>
  </>
 )
 })

}
          <NavItem className='d-flex align-items-center '>
           <div onClick={()=>toggle()}  >
          <label style={{color:'#7367f0' , fontWeight:"600" , cursor:"pointer",marginLeft:'30px' }}>+ Add new Activity Type</label>
           </div>
          </NavItem>
      </Nav>

      <TabContent activeTab={active}>
        <TabPane tabId='1'>
          <ALL/>
        </TabPane>

       {  data &&
 data?.getAllActivityTypes?.map((item,index)=> {
 return(
  <>
      <TabPane tabId={`${item?.name}${index}`}>
        <Categories title={`${item?.name}`} />
        </TabPane>
  </>
 )
 })

}
      </TabContent>
    </Fragment> */}

      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader style={{ height: "70PX" }} toggle={toggle}>
          <h5> {edit ? "Edit Category Type" : "Add Category Type"}</h5>
        </ModalHeader>
        <ModalBody className={"px-2"}>
          <form onSubmit={handleSubmit(handleCreateActivity)}>
            <Row className="mt-1">
              <Col md="12" className={"d-flex "}>
                <div className="d-flex justify-content-start align-items-center">
                  <label
                    className=""
                    style={{ fontWeight: "bold", paddingBottom: "5px" }}
                  >
                    Type
                  </label>
                </div>
              </Col>
              <Col md="12">
                <div className="w-100 d-flex justify-content-end align-items-center">
                  <Controller
                    name="type"
                    control={control}
                    defaultValue={{ label: "Gym", value: "Gym" }} // Ensure default value is set here
                    render={({ field }) => (
                      <Select
                        {...field}
                        className="w-100"
                        id="type"
                        options={options}
                        placeholder="Select Type"
                        isInvalid={!!errors?.type} // Corrected to isInvalid from invalid
                      />
                    )}
                  />
                </div>
                {errors?.type && (
                  <p className="mt-1 text-danger">{errors?.type?.message}</p>
                )}
              </Col>
            </Row>

            <Row className="mt-1">
              <Col md="12" className={"d-flex "}>
                <div className="d-flex justify-content-start align-items-center">
                  <label
                    className=""
                    style={{ fontWeight: "bold", paddingBottom: "5px" }}
                  >
                    Type Name
                  </label>
                </div>
              </Col>
              <Col md="12">
                <div className="d-flex justify-content-end align-items-center">
                  <Controller
                    name="activityType"
                    control={control}
                    defaultValue="" // Ensure default value is set here
                    render={({ field }) => (
                      <Input
                        id="activityType"
                        placeholder="Category Name"
                        isInvalid={!!errors?.activityType} // Corrected to isInvalid from invalid
                        {...field}
                      />
                    )}
                  />
                </div>
                {errors?.activityType && (
                  <p className="mt-1 text-danger">
                    {errors?.activityType?.message}
                  </p>
                )}
              </Col>
            </Row>

            <Row className={"mt-2"}>
              <Col md="3" className={"d-flex "}>
                <div className="d-flex justify-content-start align-items-center">
                  <label className="" style={{ fontWeight: "bold" }}>
                    Is Type :
                  </label>
                </div>
              </Col>
              <Col md="9">
                <div className="d-flex align-items-center">
                  <Controller
                    id="isType"
                    name="isType"
                    control={control}
                    // Ensure default value is set here
                    render={({ field }) => (
                      <Input
                        id="isType"
                        type="checkbox"
                        {...field}
                        checked={field?.value} // Ensure checkbox is controlled
                      />
                    )}
                  />
                </div>
                {errors?.isType && (
                  <p className="text-danger">{errors?.isType?.message}</p>
                )}
              </Col>

              {!edit && (
                <>
                  <Col md="3" className={"d-flex mt-2 "}>
                    <div className="d-flex justify-content-start align-items-center">
                      <label className="" style={{ fontWeight: "bold" }}>
                        Is Active :
                      </label>
                    </div>
                  </Col>

                  <Col md="9" className={"mt-2"}>
                    <div className="d-flex align-items-center">
                      <Controller
                        id="active"
                        name="active"
                        control={control}
                        // Ensure default value is set here
                        render={({ field }) => (
                          <Input
                            id="active"
                            type="checkbox"
                            {...field}
                            checked={field?.value} // Ensure checkbox is controlled
                          />
                        )}
                      />
                    </div>
                    {errors?.active && (
                      <p className="mt-1 text-danger">
                        {errors?.active?.message}
                      </p>
                    )}
                  </Col>
                </>
              )}
            </Row>

            <Row className="mt-2">
              <Col md="12">
                <div className="d-flex justify-content-center align-items-center">
                  <button
                    type="submit"
                    id="btn-hammad"
                    className="btn btn-primary"
                  >
                    {edit ? "Update" : "Create New Type"}
                  </button>
                </div>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </>
  );

}
