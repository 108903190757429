/*eslint-disable */
import React, { Fragment, useEffect, useState } from 'react'
import { Card, Col, Row, UncontrolledTooltip } from 'reactstrap'
import UserInfoCard from '../UserInfoCard'
import { GET_ORDER_BY_ID, GET_USER_BY_ID } from '../../../../../graphql/query/query'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { ACTIVE_USER, UPDATE_USER_STATUS } from '../../../../../graphql/mutation'
import Swal from 'sweetalert2'
import { ActiveOnSubmit } from '../../../../../graphql/api-callings'
import ReactPaginate from 'react-paginate'
import { formatDate } from '../../../../../utility/Utils'
import Avatar from '../../../../components/avatar'

export default function UserDetails() {
 const paramId =  useParams()
 const [ page , setPage ] = useState(1)

  const {
     loading: loading,
     error,
     data,
     refetch
   } = useQuery(GET_USER_BY_ID , {variables: {
  "input": {
   "id":parseInt(paramId?.id)
  }
}})



  const {
    loading: loading1,
    error: error1,
    data: data1,
    refetch: refetch1,
  } = useQuery(GET_ORDER_BY_ID, {
    variables: {
      input: {
        limit: 8,
        pageStart: page - 1  ,
        userID: parseInt(paramId?.id),
      },
    },
  });




 const [UpdateUserById, { loading: loading3, error: errror3, data: data3 }] =
  useMutation(ACTIVE_USER);

const handeDateAndTime = (date) => {
const dateObject = new Date(date);

// Format options for date and time
const options = {
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  hour12: true, // Set to false for 24-hour time format
};

// Format the date using toLocaleString
const formattedDate = dateObject.toLocaleString("en-US", options);


return formattedDate


}
  const CustomPagination = () => (
    <ReactPaginate
      nextLabel=""
      breakLabel="..."
      previousLabel=""
      pageRangeDisplayed={2}
      forcePage={page - 1}
      marginPagesDisplayed={2}
      activeClassName="active"
      pageClassName="page-item"
      breakClassName="page-item"
      nextLinkClassName="page-link"
      pageLinkClassName="page-link"
      breakLinkClassName="page-link"
      previousLinkClassName="page-link"
      nextClassName="page-item next-item"
      previousClassName="page-item prev-item"
      pageCount={Number(Math.ceil(data1?.getOrdersByUserId?.total / 10))}
      onPageChange={(page) => {
        setPage(page.selected + 1);
      }}
      containerClassName="pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-1"
    />
  );
//  const [modifyUserStatus, { loading: loading3, error: errror3, data: data3 }] =
 //   useMutation(UPDATE_USER_STATUS);


const column = [
  {
    name: "s.no",
    sortable: true,
    minWidth: "172px",
    sortField: "role",
    selector: (row, ind) => ind + 1,
  },
  {
    name: "name",
    sortable: true,
    minWidth: "172px",
    sortField: "role",
    selector: (row) => row?.category?.name,
  },
  {
    name: "place At",
    sortable: true,
    minWidth: "250px",
    sortField: "role",
    selector: (row) => handeDateAndTime(row?.placedAt.toString()),
  },
  {
    name: "Team",
    sortable: true,
    minWidth: "252px",
    sortField: "role",
    selector: (row) => (
      <div style={{overflow:'auto' , paddingRight:'5px' , marginRight:'5px'}}  className=" d-flex align-items-center ">
        {row?.team?.map((user, ind) => {
          return (
            <>
              {ind <= 2 ? (
                <img
                  className="pull-up"
                  style={{
                    marginLeft: ind == 0 ? "0px" : "-16px",
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                  }}
                  src={
                    row?.type == "email"
                      ? `${process?.env?.REACT_APP_OUT_URL}${row?.profilePic}`
                      : row?.profilePic
                  }
                  //  id={user?.name?.toLowerCase()?.split(' ')?.join('-')}
                  // imgHeight='26'
                  //imgWidth='26'
                />
              ) : (
                ``
              )}

              {/* <UncontrolledTooltip
                            target={user?.name?.toLowerCase().split(' ').join('-')}
                            placement='top'
                          >
                            {user?.name}
                          </UncontrolledTooltip> */}
            </>
          );
        })}

        { row?.team?.length > 3 &&  ` +${row?.team?.length - 3}`}
        {/* <a href='/' className='text-muted text-nowrap ms-50' onClick={e => e.preventDefault()}>
                    +{post.likedCount} more
                  </a> */}
      </div>
    ),
  },
  // ,
  // {
  //   name: "Type",
  //   sortable: true,
  //   minWidth: "172px",
  //   sortField: "role",
  //   selector: (row) => row?.type,
  // },
  // {
  //   name: "Actions",
  //   width: "300px",
  //   cell: (row) => (
  //     <div>
  //       {/*
  //         <Button
  //           onClick={() => handleDeleteActivity(row?.id)}
  //           className="btn btn-danger"
  //            disabled={true}
  //         >
  //           Delete
  //         </Button>
  //         */}
  //       {/* <Edit
  //         onClick={() => handleEditActivity(row)}
  //         style={{ cursor: "pointer" }}
  //       />
  //       <Eye
  //         style={{ backgroundColor: "transparent", cursor: "pointer" }}
  //         onClick={() => handleViewActivity(row)}
  //         className="ms-2 "
  //       >
  //         View
  //       </Eye> */}
  //     </div>
  //   ),
  // },
];

// useEffect(() => {

//   if (data?.role?.role=='user') {
//    refetch1();
//   }

// }, [data]);
console.log(data1,'safsaf');
// const handleinActiveHit = (status, currId) => {
//   Swal.fire({
//     title: "Are you sure?",
//     text: data?.getUserById?.status
//       ? "You want to deActivate this user!"
//       : "You want to Activate this user!",

//     showCancelButton: true,
//     confirmButtonColor: data?.getUserById?.status ? "#d33" : "#22bb33",
//     cancelButtonColor: "#d33",
//     cancelButtonMarginleft: "10px",
//     confirmButtonText: data?.getUserById?.status ? "DeActivate" : "Activate",
//   }).then((result) => {
//     if (result?.isConfirmed) {
//               console.log(status, "status");
//         const payload = {
//           // status: false,
//           modifyUserStatusId: parseInt(id),
//         };
//         ActiveOnSubmit(payload, modifyUserStatus, refetch);

//     }
//   })
// };

const handleInActiveUser = async (id) => {
  //setCurrentID(id);

  //console.log(id, "what is this");
};

console.log(paramId,'what is data')
  return (
    <Row>
      <Col xl="4" lg="5" xs={{ order: 1 }} md={{ order: 0, size: 5 }}>
        <UserInfoCard selectedUser={data?.getUserById} />
      </Col>
      {data?.getUserById?.role?.role == "user" && (
        <Col xl="8" lg="7" xs={{ order: 1 }} md={{ order: 0, size: 5 }}>
          <Card className="overflow-hidden">
            <Row>
              <Col md={12}>
                <h4 className="mx-2 mt-2">Bookings</h4>
              </Col>
            </Row>

            <div className="react-dataTable">
              <DataTable
                subHeader
                columns={column}
                data={data1?.getOrdersByUserId?.order}
                highlightOnHover
                pagination
                //paginationServer
                //paginationTotalRows={100}
                paginationPerPage={10}
                paginationComponent={CustomPagination}
                // paginationComponentOptions={{
                //     noRowsPerPage: true
                // }}
                // onChangePage={page => setPage(page)}
                //   paginationComponent={CustomPagination}
              />
            </div>
          </Card>
        </Col>
      )}
    </Row>
  );
}
