// ** Authentications
/* eslint-disable */

import toast from "react-hot-toast";
import { useContext } from "react";
import { handleLogin } from "@store/authentication";
import { getHomeRouteForLoggedInUser } from "@utils";





// ** USER/ADMIN CRUD

const handleQueryError =  ( errors  ) => {

    //   refreshToken: localStorage.getItem("refreshToken"),
    // };

   // RefreshTokenOnSubmit(payload, refreshToken, reft);
      toast.error(errors?.graphQLErrors[0]?.message, {
        theme: "dark",
      });

}

export const RegisterOnSubmit = (value, register, navigate) => {

  console.log("myValue", value);
  const userRegister = {
    email: value.email,
    password: value.password,
    roleId: 1,
  };

  register({
    variables: {
      input: userRegister,
    },

  },

)
    .then(async (response) => {
      if (response?.data?.register?.message === "Successfully created") {
        localStorage.setItem("token", response?.data?.register?.data?.token);
        localStorage.setItem("email", response?.data?.register?.data?.email);
        //  navigate(routes.DASHBOARD);
        toast.success(`User ${response?.data?.register?.message}`, {
          theme: "dark",
        });
      }
      console.log(response, "what is this");
    })
    .catch((error) => {
      // Check if the error has a response and a status code

      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request
        toast.error("Bad Request: Please check your input and try again.", {
          theme: "dark",
        });
      }

      // else {
      //   // Handle other errors
      //   toast.error("An unexpected error occurred. Please try again later.", {
      //     theme: "dark",
      //   });
      // }
      console.log(error, "error");
    });


 // navigate("/apps/user/list");
};

export const DeleteUser = (id, navigate) => {
  console.log("myid", id);

  register({
    variables: {
      input: userRegister,
    },
  })
    .then((response) => {
      console.log(response, "register response");
      toast.success("Delete User Successfully");
    })
    .catch((error) => {
      console.log(error, "register response error");
      toast.error(error.message);
    });

  navigate("/apps/user/list");
};

export const LoginOnSubmit = (value, login, navigate, dispatch, ability) => {

  console.log("myValue", value);
  const userLogin = {
    email: value.email,
    password: value.password,
     fcmToken: localStorage.getItem('fcm'),
    };

  login({
    variables: {
      input: userLogin,
    },


  }).then(async (response) => {

   const cookie = response?.headers?.getSetCookie()
      console.log(response, "headerCookie");

//       if (response?.errors?.networkError?.result?.errors?.length > 0){
//  toast.error(`${response?.errors?.networkError?.result?.errors[0]?.message}`, {
//    theme: "dark",
//  });
//  return
//       }


      if (response?.data?.login?.message === "Successfully login") {
        console.log(response, "what is this");



        const data = {
            id: 1,
            fullName: response?.data?.login?.data?.fullname,
            gender:"",
            username: response?.data?.login?.data?.fullname ?
            response?.data?.login?.data?.fullname :
            "Admin",
            password: "",
            avatar:
  response?.data?.login?.data?.profilePic ?
  response?.data?.login?.data?.profilePic :
  require("@src/assets/images/portrait/small/avatar-s-11.jpg")
              .default,
            email: response?.data?.login?.data?.email,
            role: "admin",
            ability: [
              {
                action: "manage",
                subject: "all",
              },
            ],
            accessToken: `${response?.data?.login?.data?.token}`,

        };
        console.log(data, "current")

       if(response?.data?.login?.message == "Successfully login"){
       dispatch(handleLogin(data));
       localStorage.setItem(
         "refreshToken",
         response?.data?.login?.data?.refreshToken
       );
       ability.update(data.ability);
      navigate(getHomeRouteForLoggedInUser(data.role))

      toast.success(`${response?.data?.login?.message}`, {
          theme: "dark",
        });

       }

        //  localStorage.setItem("token", response?.data?.login?.data?.token);
        // localStorage.setItem("email", response?.data?.login?.data?.email);
        //  navigate(routes.DASHBOARD);

      }
    })
    .catch((error) => {
      // Check if the error has a response and a status code

      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request
        toast.error("Bad Request: Please check your input and try again.", {
          theme: "dark",
        });
      // } else {
      //   // Handle other errors
      //   toast.error("Invalid credentials", {
      //     theme: "dark",
      //   });
      }
      console.log(error, "error");
    });

  // navigate(routes.DASHBOARD);
  //  window.location.href = process.env.REACT_APP_OUT_URL;
};

export const ResetOnSubmit = (value, forgetPassword, navigate) => {
  console.log("myValue", value);
  const userReset = {
    email: value.email,
  };

  forgetPassword({
    variables: {
      input: userReset,
    },
  })
    .then((response) => {

        if (response?.errors?.networkError?.result?.errors?.length > 0) {
          toast.error(
            `${response?.errors?.networkError?.result?.errors[0]?.message}`,
            {
              theme: "dark",
            }
          );
          return;
        }


      toast.success(`${response?.data?.forgetPassword?.message}`, {
        theme: "dark",
      });
      localStorage.setItem("verifyEmail", value?.email);
      navigate("/otp");
    })
    .catch((error) => {
      // Check if the error has a response and a status code

      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request
        toast.error("Bad Request: Please check your input and try again.", {
          theme: "dark",
        });
      }
      console.log(error, "error");
    });

  //navigate(routes.OTP);
};

export const VerfyOnSubmit = (value, verifyCode, navigate) => {
  console.log("myValue", value);

  verifyCode({
    variables: {
      input: value,
    },
  })
    .then((response) => {

        if (response?.errors?.networkError?.result?.errors?.length > 0) {
          toast.error(
            `${response?.errors?.networkError?.result?.errors[0]?.message}`,
            {
              theme: "dark",
            }
          );
          return;
        }

      toast.success(`${response?.data?.verifyCode?.message}`, {
        theme: "dark",
      });
       navigate('/reset-password');
    })
    .catch((error) => {
      // Check if the error has a response and a status code
     console.log(error,'wasfasf')
      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request
        toast.error("Bad Request: Please check your input and try again.", {
          theme: "dark",
        });
      }
      console.log(error, "error");
    });

  //navigate(routes.OTP);
};


export const NewPasswordOnSubmit = (value, resetPassword, navigate) => {
  console.log("myValue", value);


  resetPassword({
    variables: {
      input: value,
    },
  })
    .then((response) => {

  if (response?.errors?.networkError?.result?.errors?.length > 0) {
    toast.error(
      `${response?.errors?.networkError?.result?.errors[0]?.message}`,
      {
        theme: "dark",
      }
    );
    return;
  }

      toast.success(`${response?.data?.resetPassword?.message}`, {
        theme: "dark",
      });
       navigate("/login");
    })
    .catch((error) => {
      // Check if the error has a response and a status code
      console.log(error, "wasfasf");
      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request
        toast.error("Bad Request: Please check your input and try again.", {
          theme: "dark",
        });
      }
      console.log(error, "error");
    });

};

// ** CHANNEL & CATEGORY

export const AddCategory = (value, createCategory, navigate) => {
  console.log("myValue", value);

  const addCategory = {
    category: value.category,
    // archive: value.archive,
  };

  createCategory({
    variables: {
      input: addCategory,
    },
  })
    .then((response) => {
      console.log(response, "register response");
      toast.success("Category Created Successfully");
    })
    .catch((error) => {
      console.log(error, "register response error");
      toast.error(error.message);
    });

  navigate("/apps/categories/channel");
};

export const AddChannel = (value, createChannel, toggleSidebar) => {
  console.log("myValue", value);

  const addChannel = {
    link: value.link,
    channel: value.channel,
    categoryId: value.categoryId,
    video: value.video,
  };

  const input = {
    ...addChannel,
  };

  delete input.video;

  if (addChannel?.video === "No Video") {
    createChannel({
      variables: {
        input: input,
      },
    })
      .then((response) => {
        toast.success("Channel Created Successfully");
        toggleSidebar();
      })
      .catch((error) => {
        console.log(error, "Channel response error");
        toast.error(error.message);
      });
  } else {
    createChannel({
      variables: {
        video: addChannel.video,
        input: input,
      },
    })
      .then((response) => {
        toast.success("Channel Created Successfully");
        toggleSidebar();
      })
      .catch((error) => {
        console.log(error, "Channel response error");
        toast.error(error.message);
      });
  }
};

export const AddVideo = (value, uploadVideo, toggleSidebar) => {
  console.log("myValue", value);

  const addChannel = {
    // link: value.link,
    // channel: value.channel,
    // categoryId: value.categoryId,
    video: value.video,
  };

  uploadVideo({
    variables: {
      video: value.video
      // input: input,
    },
  })
    .then((response) => {
      toast.success("Channel Created Successfully");
      toggleSidebar();
    })
    .catch((error) => {
      console.log(error, "Channel response error");
      toast.error(error.message);
    });
}

export const UpdateProfileOnSubmit = (
  value,
  updateUser,
  navigate,
  profile,
  refetch
) => {
  console.log("myValue", value);

  updateUser({
    variables: {
      input: value,
      profilePic: profile,
    },
  })
    .then((response) => {

  if (response?.errors?.networkError?.result?.errors?.length > 0) {
    toast.error(
      `${response?.errors?.networkError?.result?.errors[0]?.message}`,
      {
        theme: "dark",
      }
    );
    return;
  }

      toast.success(`${response?.data?.updateUser?.message}`, {
        theme: "dark",
      });
      refetch();
      // navigate(routes.PROFILE);
    })
    .catch((error) => {
      // Check if the error has a response and a status code


      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request
        toast.error("Bad Request: Please check your input and try again.", {
          theme: "dark",
        });
      }

      console.log(error, "error");
    });
};

export const ActiveOnSubmit = (value, modifyUserStatus, refetch1) => {
  console.log("myValue", value)

  modifyUserStatus({
    variables: {
      modifyUserStatusId: value?.modifyUserStatusId,
    },
  })
    .then((response) => {
      // if (response?.errors?.networkError?.result?.errors?.length > 0) {
      //   toast.error(
      //     `${response?.errors?.networkError?.result?.errors[0]?.message}`,
      //     {
      //       theme: "dark",
      //     }
      //   );
      //   return;
      // }

      toast.success(`${response?.data?.modifyUserStatus?.message}`, {
        theme: "dark",
      });
      refetch1();
      // navigate(routes.PROFILE);
    })
    .catch((error) => {
      // Check if the error has a response and a status code

      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request
        toast.error("Bad Request: Please check your input and try again.", {
          theme: "dark",
        });
      }
      console.log(error, "error");
    });
};

export const CreateActivityOnSubmit = (value, createActivityType , refetch  , modal ) => {
  console.log("myValue", value);

  createActivityType({
    variables: {
      input: value,
    },
  })
    .then((response) => {
          console.log(response,'res--->')
        // if (response?.errors?.networkError?.result?.errors?.length > 0) {
        //   toast.error(
        //     `${response?.errors?.networkError?.result?.errors[0]?.message}`,
        //     {
        //       theme: "dark",
        //     }
        //   );
        //  // return;
        // }

      toast.success(`${response?.data?.createActivityType?.message}`, {
        theme: "dark",
      });
      refetch()
      modal(false);
      // navigate(routes.PROFILE);
    })
    .catch((error) => {
      // Check if the error has a response and a status code

      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request
        toast.error("Bad Request: Please check your input and try again.", {
          theme: "dark",
        });
      }
      console.log(error, "error");
    });
}

export const DeleteActivityOnSubmit = (value, deleteActivityType, refetch) => {
  console.log("myValue", value);

  deleteActivityType({
    variables: {
      input: value,
    },
  })
    .then((response) => {

  if (response?.errors?.networkError?.result?.errors?.length > 0) {
    toast.error(
      `${response?.errors?.networkError?.result?.errors[0]?.message}`,
      {
        theme: "dark",
      }
    );
    return;
  }

      toast.success(`${response?.data?.deleteActivityType?.message}`, {
        theme: "dark",
      });
      refetch()

      // navigate(routes.PROFILE);
    })
    .catch((error) => {
      // Check if the error has a response and a status code

      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request
        toast.error("Bad Request: Please check your input and try again.", {
          theme: "dark",
        });
      } else {
        // Handle other errors
        toast.error("An unexpected error occurred. Please try again later.", {
          theme: "dark",
        });
      }
      console.log(error, "error");
    });
}


export const UpdateActivityOnSubmit = (value, updateActivityType, refetch, modal) => {
  console.log("myValue", value);

  updateActivityType({
    variables: {
      input: value,
    },
  })
    .then((response) => {


        if (response?.errors?.networkError?.result?.errors?.length > 0) {
          toast.error(
            `${response?.errors?.networkError?.result?.errors[0]?.message}`,
            {
              theme: "dark",
            }
          );
          return;
        }

      toast.success(`${response?.data?.updateActivityType?.message}`, {
        theme: "dark",
      });
      refetch();
      modal(false);
      // navigate(routes.PROFILE);
    })
    .catch((error) => {
      // Check if the error has a response and a status code

      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request
        toast.error("Bad Request: Please check your input and try again.", {
          theme: "dark",
        });
      } else {
        // Handle other errors
        toast.error("An unexpected error occurred. Please try again later.", {
          theme: "dark",
        });
      }
      console.log(error, "error");
    });
}

export const AddActivityTypeOnSubmit = (
  value,
  createActivity,
  typeFeatureImage,
  typeImages,
  refetch,
  modal,
  handleReset
) => {
  console.log("myValue", typeFeatureImage);

  createActivity({
    variables: {
      picture: typeImages?.length > 0 ? typeImages : null ,
      featuredImage: typeFeatureImage ? typeFeatureImage : null ,
      input: value,
    },
  }).then((response) => {

  // if (response?.errors?.networkError?.result?.errors?.length > 0) {
  //   toast.error(
  //     `${response?.errors?.networkError?.result?.errors[0]?.message}`,
  //     {
  //       theme: "dark",
  //     }
  //   );
  //   return;
  // }

      toast.success(`${response?.data?.createActivity?.message}`, {
        theme: "dark",
      });
      refetch();
      modal(false);
      handleReset();
      // navigate(routes.PROFILE);
    })
    .catch((error) => {
      // Check if the error has a response and a status code

      // if (error?.response && error?.response?.status === 400) {
      //   // Handle 400 Bad Request
      //   toast?.error("Bad Request: Please check your input and try again.", {
      //     theme: "dark",
      //   });
      // }

      // if (error?.response && error?.response?.status === 500) {
      //   // Handle 400 Bad Request
      //   toast?.error("Bad Request: Please check your input and try again.", {
      //     theme: "dark",
      //   });
      // }
      console.log(error, "error")
    });
}

export const DeleteActivityTypeOnSubmit = (value, deleteActivity, refetch) => {
  console.log("myValue", value)

  deleteActivity({
    variables: {
      input: value,
    },
  })
    .then((response) => {

  if (response?.errors?.networkError?.result?.errors?.length > 0) {
    toast.error(
      `${response?.errors?.networkError?.result?.errors[0]?.message}`,
      {
        theme: "dark",
      }
    );
    return;
  }

      toast.success(`${response?.data?.deleteActivity?.message}`, {
        theme: "dark",
      });
      refetch()

      // navigate(routes.PROFILE);
    })
    .catch((error) => {
      // Check if the error has a response and a status code

      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request
        toast.error("Bad Request: Please check your input and try again.", {
          theme: "dark",
        });
      }
      console.log(error, "error");
    });
}

export const UpdateActivityTypeOnSubmit = (
  value,
  updateActivity,
  typeFeatureImage,
  typeImages,
  refetch,
  modal,
  handleReset,
  remove
) => {
  console.log("myValue", value);

  updateActivity({
    variables: {
      picture: typeImages,
      featuredImage: typeFeatureImage,
      removePictures:remove,
      input: value,
    },
  }).then((response) => {

  if (response?.errors?.networkError?.result?.errors?.length > 0) {
    toast.error(
      `${response?.errors?.networkError?.result?.errors[0]?.message}`,
      {
        theme: "dark",
      }
    );
    return;
  }

    toast.success(`${response?.data?.updateActivity?.message}`, {
        theme: "dark",
      });
      refetch();
      modal(false);
      handleReset();
      // navigate(routes.PROFILE);
    })
    .catch((error) => {
      // Check if the error has a response and a status code

      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request
        toast.error("Bad Request: Please check your input and try again.", {
          theme: "dark",
        });
      } else {
        // Handle other errors
        toast.error("An unexpected error occurred. Please try again later.", {
          theme: "dark",
        });
      }
      console.log(error, "error");
    });
}

export const AddActivityOnSubmit = (
  value,
  createCategory,
  typeImages,
  refetch,
  modal,
  handleReset,

) => {
  console.log("myValue", value);

  createCategory({
    variables: {
      pictures: typeImages,
      input: value,
    }
  }).then((response) => {
          console.log(
            response?.errors?.networkError?.result?.errors[0]?.message,
            "headerCookie"
          );
  if (response?.errors?.networkError?.result?.errors?.length > 0) {
    toast.error(
      `${response?.errors?.networkError?.result?.errors[0]?.message}`,
      {
        theme: "dark",
      }
    );

  }

      toast.success(`${response?.data?.createCategory?.message}`, {
        theme: "dark",
      });
      refetch();
      modal(false);
      handleReset();
      // navigate(routes.PROFILE);
    }).catch((error) => {
      // Check if the error has a response and a status code
     console.log(error,'errr')
      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request
        toast.error("Bad Request: Please check your input and try again.", {
          theme: "dark",
        });
      }

      if (error.response && error.response.status === 500)
        // Handle other errors
        toast.error("An unexpected error occurred. Please try again later.", {
          theme: "dark",
        });

      console.log(error, "error");
    });
};

export const DeleteCategoryOnSubmit = (value, deleteCategory, refetch) => {
  console.log("myValue", value);

  deleteCategory({
    variables: {
      input: value,
    },
  })
    .then((response) => {

  if (response?.errors?.networkError?.result?.errors?.length > 0) {
    toast.error(
      `${response?.errors?.networkError?.result?.errors[0]?.message}`,
      {
        theme: "dark",
      }
    );
    return;
  }

      toast.success(`${response?.data?.deleteCategory?.message}`, {
        theme: "dark",
      });
      refetch();

      // navigate(routes.PROFILE);
    })
    .catch((error) => {
      // Check if the error has a response and a status code

      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request
        toast.error("Bad Request: Please check your input and try again.", {
          theme: "dark",
        });
      }
      console.log(error, "error");
    });
};

export const UpdateCategoryOnSubmit = (
  value,
  updateCategory,
  typeImages,
  refetch,
  modal,
  handleReset,
  orimage,
  remove
) => {
  console.log("myValue", value);

  updateCategory({
    variables: {
      pictures: typeImages?.length ? typeImages : null,
     // logo: orimage ? orimage : null,
      input: value,
      removePictures:remove ,
    },
  })
    .then((response) => {
      if (response?.errors?.networkError?.result?.errors?.length > 0) {
        toast.error(
          `${response?.errors?.networkError?.result?.errors[0]?.message}`,
          {
            theme: "dark",
          }
        );
        return;
      }

      toast.success(`${response?.data?.updateCategory?.message}`, {
        theme: "dark",
      });
      refetch();
      modal(false);
      handleReset();
      // navigate(routes.PROFILE);
    })
    .catch((error) => {
      // Check if the error has a response and a status code

      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request
        toast.error("Bad Request: Please check your input and try again.", {
          theme: "dark",
        });
      }
      console.log(error, "error");
    });
};

export const RefreshTokenOnSubmit = (value, refreshToken) => {
  console.log("myValue", value);

  refreshToken({
    variables: {
      input: value,
    },
  })
    .then( async (response) => {

        if (response?.errors?.networkError?.result?.errors?.length > 0) {
          toast.error(
            `${response?.errors?.networkError?.result?.errors[0]?.message}`,
            {
              theme: "dark",
            }
          );
          return;
        }
      // toast.success(`${response?.data?.updateCategory?.message}`, {
      //   theme: "dark",
      // });
      await localStorage.setItem(
        "refreshToken",
        response?.data?.refreshToken?.data?.refreshToken
      )
     await  localStorage.setItem(
        "accessToken",
        response?.data?.refreshToken?.data?.accessToken
      )
      refetch()
      // navigate(routes.PROFILE);
    })
    .catch((error) => {
      // Check if the error has a response and a status code

      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request
        toast.error("Bad Request: Please check your input and try again.", {
          theme: "dark",
        });

      }
      console.log(error, "error");
    });
}


export const CreateOrganizerOnSubmit = (
  value,
  logo,
  createOrganizer,
  refetch,
  modal
) => {
  console.log("myValue", value);

  createOrganizer({
    variables: {
      logo: logo,
      input: value,
    },
  })
    .then((response) => {
        if (response?.errors?.networkError?.result?.errors?.length > 0) {
          toast.error(
            `${response?.errors?.networkError?.result?.errors[0]?.message}`,
            {
              theme: "dark",
            }
          );
          return;
        }
      toast.success(`${response?.data?.createOrganizer?.message}`, {
        theme: "dark",
      });
      refetch();
      modal(false);
      // navigate(routes.PROFILE);
    })
    .catch((error) => {
      // Check if the error has a response and a status code

      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request
        toast.error("Bad Request: Please check your input and try again.", {
          theme: "dark",
        });
      }
      console.log(error, "error");
    });
};


export const UpdateOrganizerOnSubmit = (
  value,
  logo,
  updateOrganizer,
  refetch,
  modal
) => {
  console.log("myValue", value);

  updateOrganizer({
    variables: {
      logo: logo ? logo : null,
      input: value,
    },
  })
    .then((response) => {

  if (response?.errors?.networkError?.result?.errors?.length > 0) {
    toast.error(
      `${response?.errors?.networkError?.result?.errors[0]?.message}`,
      {
        theme: "dark",
      }
    );
    return;
  }

      toast.success(`${response?.data?.updateOrganizer?.message}`, {
        theme: "dark",
      });
      refetch();
      modal(false);
      // navigate(routes.PROFILE);
    })
    .catch((error) => {
      // Check if the error has a response and a status code

      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request
        toast.error("Bad Request: Please check your input and try again.", {
          theme: "dark",
        });
      }

      console.log(error, "error");
    });
};


export const DeleteOrganizerOnSubmit = (value, deleteOrganizer, refetch) => {
  console.log("myValue", value);

  deleteOrganizer({
    variables: {
      input: value,
    },
  })
    .then((response) => {

        if (response?.errors?.networkError?.result?.errors?.length > 0) {
          toast.error(
            `${response?.errors?.networkError?.result?.errors[0]?.message}`,
            {
              theme: "dark",
            }
          );
          return;
        }
      toast.success(`${response?.data?.deleteOrganizer?.message}`, {
        theme: "dark",
      });
      refetch();

      // navigate(routes.PROFILE);
    })
    .catch((error) => {
      // Check if the error has a response and a status code

      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request
        toast.error("Bad Request: Please check your input and try again.", {
          theme: "dark",
        });
      }
      console.log(error, "error");
    });
};

export const ActiveTypeStatusOnSubmit = (
  value,
  modifyActivityTypeStatus,
  refetch1
) => {
  console.log("myValue", value);

   modifyActivityTypeStatus({
     variables: {
       activityTypeId: value?.activityTypeId,
     },
   })
     .then((response) => {
       // if (response?.errors?.networkError?.result?.errors?.length > 0) {
       //   toast.error(
       //     `${response?.errors?.networkError?.result?.errors[0]?.message}`,
       //     {
       //       theme: "dark",
       //     }
       //   );
       //   return;
       // }

       toast.success(`${response?.data?.modifyActivityTypeStatus?.message}`, {
         theme: "dark",
       });
       refetch1();
       // navigate(routes.PROFILE);
     })
     .catch((error) => {
       // Check if the error has a response and a status code

       if (error.response && error.response.status === 400) {
         // Handle 400 Bad Request
         toast.error("Bad Request: Please check your input and try again.", {
           theme: "dark",
         });
       }
       console.log(error, "error");
     });
};
