/* eslint-disable no-unused-vars */
/* eslint-disable */

// ** React Imports
import { Fragment, useState, useEffect } from "react";

// ** Invoice List Sidebar
import Sidebar from "./Sidebar";

// ** Table Columns

import { Link } from "react-router-dom";

// ** Custom Components
import Avatar from "@components/avatar";
import { FaEye } from "react-icons/fa";
// ** Store & Actions
import { getAllData, getData } from "../store";
import { useDispatch, useSelector } from "react-redux";
//import { MapContainer, TileLayer, useMap } from "react-leaflet";

// ** Third Party Components
import Select from "react-select";
import ReactPaginate from "react-paginate";
import DataTable from "react-data-table-component";
import {
  ChevronDown,
  Share,
  Printer,
  FileText,
  File,
  Grid,
  Copy,
  Eye,
} from "react-feather";
import { DeleteUser } from "../../../../graphql/api-callings";

// ** Utils
import { selectThemeColors } from "@utils";


// ** Reactstrap Imports
import {
  Row,
  Col,
  Card,
  Input,
  Label,
  Button,
  CardBody,
  CardTitle,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Badge,
} from "reactstrap";

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";
import "@styles/react/libs/tables/react-dataTable-component.scss";
import defaultAvatar from "@src/assets/images/portrait/small/avatar-s-11.jpg";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { Controller, useForm } from "react-hook-form";


// ** Table Header

const statusOptions = [
  { label: "All", value: null },
  { label: "Active", value: true },
  { label: "InActive", value: false },
];
const UsersList = (props) => {
  // ** Store Vars
  const {
    page,
    setPage,
    handleActive,
    toggle,
    role,
    handleFilter,
    formattedOptions,
    setRemove,
    remove,
    active,
  } = props;
  const dispatch = useDispatch();
  const store = useSelector((state) => state.users);
const navigate = useNavigate()
  //console.log(props?.data.getAllUsers, "hammaddata");

  // ** States
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);


   const {
     control,
     setValue,
     setError,
     handleSubmit,
     reset,
     formState: { errors },
   } = useForm({
     defaultValues: {
       role: { label: "All", value: null },
       status: { label: "All", value: null },
       name: "",
     },
   });


const column = [
  {
    name: "User",
    sortable: true,
    minWidth: "300px",
    sortField: "fullName",
    selector: (row) => row?.fullname,
    cell: (row) => (
      <div className="d-flex justify-content-left align-items-center">
        {renderClient(row)}
        <div className="d-flex flex-column">
          <div className="user_name text-truncate text-body" onClick={() => {}}>
            <span className="fw-bolder">{row?.fullname}</span>
          </div>
          <small className="text-truncate text-muted mb-0">{row?.email}</small>
        </div>
      </div>
    ),
  },
  {
    name: "Role",
    sortable: true,
    minWidth: "172px",
    sortField: "role",
    selector: (row) =>
      row?.role?.role?.includes("admin") ? (
        <div className="d-flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="text-danger me-50"
          >
            <path d="M14.5 10c-.83 0-1.5-.67-1.5-1.5v-5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5z"></path>
            <path d="M20.5 10H19V8.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"></path>
            <path d="M9.5 14c.83 0 1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5S8 21.33 8 20.5v-5c0-.83.67-1.5 1.5-1.5z"></path>
            <path d="M3.5 14H5v1.5c0 .83-.67 1.5-1.5 1.5S2 16.33 2 15.5 2.67 14 3.5 14z"></path>
            <path d="M14 14.5c0-.83.67-1.5 1.5-1.5h5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-5c-.83 0-1.5-.67-1.5-1.5z"></path>
            <path d="M15.5 19H14v1.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5-.67-1.5-1.5-1.5z"></path>
            <path d="M10 9.5C10 8.67 9.33 8 8.5 8h-5C2.67 8 2 8.67 2 9.5S2.67 11 3.5 11h5c.83 0 1.5-.67 1.5-1.5z"></path>
            <path d="M8.5 5H10V3.5C10 2.67 9.33 2 8.5 2S7 2.67 7 3.5 7.67 5 8.5 5z"></path>
          </svg>
          {row?.role?.role}
        </div>
      ) : (
        <div className="d-flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="text-primary me-50"
          >
            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
            <circle cx="12" cy="7" r="4"></circle>
          </svg>
          {row?.role?.role}
        </div>
      ),
  },
  {
    name: "Phone",
    sortable: true,
    minWidth: "172px",
    sortField: "role",
    selector: (row) =>
      row?.phoneNumber ? (
        row?.phoneNumber
      ) : (
        <div className="d-flex justify-content-center">{"--------"}</div>
      ),
    },
  // },

  // {
  //   name: "Status",
  //   sortable: true,
  //   minWidth: "172px",
  //   sortField: "role",
  //   selector: (row) => (
  //     <Badge
  //       className="text-capitalize"
  //       color={row?.status ? "light-success" : "light-secondary"}
  //       pill
  //     >
  //       {row?.status ? "Active" : "InActive"}
  //     </Badge>
  //   ),
  // },
  {
    name: "Status",
    sortable: true,
    minWidth: "172px",
    sortField: "role",
    selector: (row) => (
      row?.role.role == 'admin' ?
      <></>
      :
      <>
        <label
        //onClick={() =>
        //active(row?.status, row?.id)}
        class="switch">
          <input
            type="checkbox"
           checked={row?.status}
            onChange={(e) => active(row?.status, row?.id)}
          />
          <span class="slider"></span>
        </label>
      </>
      // <Badge
      //   className="text-capitalize"
      //   color={row?.status ? "light-success" : "light-secondary"}
      //   pill
      // >
      // {row?.status ? "Active" : "InActive"}
      // </Badge>
    ),
  },
  {
    name: "Action",
    sortable: true,
    minWidth: "172px",

    sortField: "role",
    selector: (row) => (
      <div style={{ cursor: "pointer" }} className="text-center">
        <Eye
          size={17}
          className=""
          style={{ cursor: "pointer" }}
          onClick={() => {
            // toggle()
            handleActive(row?.id)
            navigate(`/user/view/${row?.id}`)
          }}
        >
          Details
        </Eye>
      </div>
    ),
  },
];

 const renderClient = (row) => {

  if (!row?.profilePic?.length || row?.profilePic.includes('http') ) {
    console.log(row.profilePic, "asfasf");
    return (
      <Avatar
        className="me-1"
        img={defaultAvatar}
        //img={`${process.env.REACT_APP_OUT_URL}${row?.profilePic}`}
        width="32"
        height="32"
      />
    );
  }else{

     return (
       <Avatar
         className="me-1"
         //img={defaultAvatar}
         img={`${process.env.REACT_APP_OUT_URL}${row?.profilePic}`}
         width="32"
         height="32"
       />
     )
  }
};



  const newdATA = getAllData()
console.log(newdATA, "NEW");

  const toggleSidebar = () => props?.setSidebarOpen(!props?.sidebarOpen);


  // ** Function in get data on page change
  const handlePagination = (page) => {
    console.log(page,'page')

    setCurrentPage(page?.selected + 1);
  };



  // ** Function in get data on rows per page
  const handlePerPage = (e) => {
    const value = parseInt(e.currentTarget.value);
    console.log(value, "value");

    setRowsPerPage(value);
  };

  // ** Function in get data on search query change
  // const handleFilter = (val) => {
  //   setSearchTerm(val);

  // };

  const handleReset = () => {
    reset({
      role: { label: "All", value: null },
      status: { label: "All", value: null },
      name: "",
    });
    setRemove(false)
    document.getElementById("fillter").click()

  }



  // ** Custom Pagination
  const CustomPagination = () => (

        <ReactPaginate
            nextLabel=''
            breakLabel='...'
            previousLabel=''
            pageRangeDisplayed={2}
            forcePage={page - 1}
            marginPagesDisplayed={2}
            activeClassName='active'
            pageClassName='page-item'
            breakClassName='page-item'
            nextLinkClassName='page-link'
            pageLinkClassName='page-link'
            breakLinkClassName='page-link'
            previousLinkClassName='page-link'
            nextClassName='page-item next-item'
            previousClassName='page-item prev-item'
            pageCount={Number(Math.ceil(props?.data?.getAllUsers?.totalUsers
 / 10))}
            onPageChange={page => setPage(page?.selected + 1)}
            containerClassName='pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-1'
        />
    )


   return (
     <Fragment>
       <Card className="overflow-hidden pb-4">
         <Row></Row>
         <div className="react-dataTable">
           <div className="invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75">
             <Row>
               <Col xl="6" className="d-flex align-items-center p-0">
                 <h2 className="ms-2">Users</h2>
               </Col>
               <Col
                 xl="6"
                 className="d-flex align-items-sm-center justify-content-xl-end justify-content-start flex-xl-nowrap flex-wrap flex-sm-row flex-column pe-xl-1 p-0 mt-xl-0 mt-1"
               >
                 {/* <div className="d-flex align-items-center mb-sm-0 mb-1 me-1">
            <label className="mb-0" htmlFor="search-invoice">
              Search:
            </label>
            <Input
              id="search-invoice"
              className="ms-50 w-100"
              type="text"
              value={searchTerm}
              onChange={(e) => handleFilter(e.target.value)}
            />
          </div> */}

                 <div className="d-flex align-items-center table-header-actions">
                   {/* <Button
              className="add-new-user"
              color="primary"
              onClick={toggleSidebar}
            >
              Add New User
            </Button> */}
                 </div>
               </Col>
             </Row>
           </div>
           <form onSubmit={handleSubmit(handleFilter)}>
             <Row>
               <Col lg="3">
                 <div>
                   <Label className={"ms-2"} style={{ fontWeight: "bold" }}>
                     Role
                   </Label>
                 </div>
                 {formattedOptions && (
                   <Controller
                     name="role"
                     control={control}
                     render={({ field }) => (
                       <ReactSelect
                         {...field}
                         className="ms-2"
                         onChange={(selectedOption) => {
                           field.onChange(selectedOption); // Update the field value in RHF
                           setRemove(true); // Execute your custom logic
                           console.log("Selected option:", selectedOption);
                         }}
                         options={formattedOptions}
                       />
                     )}
                   />
                 )}
               </Col>

               <Col lg="3">
                 <div>
                   <Label className={"ms-2"} style={{ fontWeight: "bold" }}>
                     Status
                   </Label>
                 </div>
                 {statusOptions && (
                   <Controller
                     name="status"
                     control={control}
                     render={({ field }) => (
                       <ReactSelect
                         {...field}
                         onChange={(selectedOption) => {
                           field.onChange(selectedOption); // Update the field value in RHF
                           setRemove(true); // Execute your custom logic
                           console.log("Selected option:", selectedOption);
                         }}
                         className="ms-2"
                         options={statusOptions}
                       />
                     )}
                   />
                 )}
               </Col>

               <Col lg="3">
                 <div className="">
                   <div>
                     <Label className={"ms-2"} style={{ fontWeight: "bold" }}>
                       FullName
                     </Label>
                   </div>
                   <Controller
                     name="name"
                     control={control}
                     render={({ field }) => (
                       <input
                         {...field}
                         type="text"
                         onChange={(e) => {
                           field.onChange(e)
                           setRemove(true)

                           // Pass the event back to RHF
                           // Your custom logic
                         }}
                         className="form-control ms-2"
                         placeholder="Filter by FullName"
                       />
                     )}
                   />
                 </div>
               </Col>

               <Col lg="3" className={"d-flex"}>
                 <div className="d-flex align-items-center justify-content-end">
                   <button
                     id="fillter"
                     type="submit"
                     style={{ marginTop: "20px" }}
                     className="btn btn-primary"
                   >
                     Apply Filter
                   </button>
                   {remove && (
                     <button
                       type="button"
                       style={{ marginTop: "20px" }}
                       className=" mx-1 btn btn-danger"
                       onClick={() => handleReset()}
                     >
                       X
                     </button>
                   )}
                 </div>
               </Col>
             </Row>
           </form>

           {props?.data && (
             <DataTable
               subHeader
               columns={column}
               data={props?.data?.getAllUsers?.users}
               highlightOnHover
               pagination
               //paginationServer
               //paginationTotalRows={100}
               paginationPerPage={10}
               // paginationComponentOptions={{
               //     noRowsPerPage: true
               // }}
               // onChangePage={page => setPage(page)}
               paginationComponent={CustomPagination}
             />
           )}
         </div>
       </Card>

       <Sidebar open={props.sidebarOpen} toggleSidebar={toggleSidebar} />
     </Fragment>
   );
};

export default UsersList;
