/*eslint-disable */
import React, { useState } from "react";

const IterateUplaod = (props) => {

 const {
  error,
   singleFileObj,
   singleFileArray,
   setSingleFile,
   singleFile,
   setTypeImages,
   typeImages,
   typeImagesObj,
   typeImagesArray,
 } = props;

  //var singleFileObj = [];
  //var singleFileArray = [];
  //const [singleFile, setSingleFile] = useState([]);

  const uploadSingleFiles = (e) => {
    singleFileObj?.push(e.target.files);
    typeImagesObj?.push(e.target.files);
    typeImagesArray?.push( singleFileObj[0][0] );
    singleFileArray?.push(URL.createObjectURL(singleFileObj[0][0]));
    setSingleFile([...singleFile, singleFileArray])
    error(null)
   // setTypeImages([...typeImages, typeImagesArray]);


     const filesArray = Array?.from(e.target.files);

     // Update state with the new array of objects
     setTypeImages((prevImages) => [...prevImages, ...filesArray]);
  };

  console.log(singleFile);

  const uploadFiles = (e) => {
    e.preventDefault();
    console.log(singleFile);
  };

  const removeImage = (index) => {
    console.log("reomve");
    setSingleFile([
      ...singleFile?.slice(0, index),
      ...singleFile?.slice(index + 1, singleFile?.length),
    ]);
setTypeImages([
  ...typeImages?.slice(0, index),
  ...typeImages?.slice(index + 1, typeImages?.length),
]);

  };

  return (
    <>
      <div className="container">
        <div
          style={{ fontWeight: "bold" }}
          className=" pb-2 form-group multi-preview"
        >
          <div className="d-flex flex-wrap">
            {singleFile?.length !== 0 &&
              singleFile?.map((url, index) => (
                <div key={url} className="">
                  <div className="img-block bg-gray">
                    <img className="img-fluid2" src={url} alt="..." />
                    <span
                      className="remove_img"
                      onClick={() => removeImage(index)}
                    >
                      X
                    </span>
                  </div>
                </div>
              ))}

            {singleFile?.length > 100 ? null : (
              <div className="col-md-12">
                <div className="form-group">
                  <div className="upload-btn-wrapper">
                    <button className="image-btn"> + </button>
                    <input
                      type="file"
                      accept="image/jpeg, image/png, image/gif, image/webp image/webp, image/heic, image/heif"
                      name="myfile"
                      onChange={uploadSingleFiles}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default IterateUplaod;