/* eslint-disable */

import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query GetUsers {
    users {
      id
      name
      email
      type
      fullname
      phoneNumber
      profilePic
      dob
      gender
      aboutMe
      archive
    }
  }
`;

export const GET_CHANNEL = gql`
  query Query($input: channel) {
    getAllChannels(input: $input)
  }
`;

export const GET_CATEGORIES = gql`
  query Query($input: category) {
    getAllCategories(input: $input)
  }
`;

export const GET_ALL_USERS = gql`
  query GetAllUsers($input: userInput) {
    getAllUsers(input: $input) {
      totalUsers
      nextPage
      users {
        id
        email
        type
        fullname
        phoneNumber
        gender
        profilePic
        status
        role {
          id
          role
        }
      }
    }
  }
`;


export const GET_ADMIN_PROFILE = gql`
query GetUser {
  getUser {
    id
    email
    fullname
    phoneNumber
    dob
    gender
    aboutMe
    interests
    profilePic
    notification
    fcm
  }
}`


export const GET_USER_BY_ID = gql`
  query GetUserById($input: userID!) {
    getUserById(input: $input) {
      id
      email
      type
      fullname
      phoneNumber
      dob
      gender
      aboutMe
      interests
      profilePic
      status
      isTrainer
      role {
        id
        role
      }
    }
  }
`;


export const GET_ACTIVITY_TYPES = gql`
query GetAllActivityTypesList($input: allActivityTypesInput!) {
  getAllActivityTypesList(input: $input) {
    total
    nextPage
    activity {
      id
      name
      active
      isType
      type
    }
  }
}`;


export const GET_ACTIVITY_BY_ID = gql`
query GetAllActivitiesByAdmin($input: activityInput!) {
  getAllActivitiesByAdmin(input: $input) {
    activities {
      featuredImage
      id
      name
      picture
      typeId
    }
    nextPage
    totalActivities
  }
}`


export const GET_CATEGORY_BY_ID = gql`
  query GetCategories($input: categoryInput) {
    getCategories(input: $input) {
      category {
        activityId
        availableAt
        averageRating
        capacity
        description
        distance
        id
        location {
          address
          city
          country
          lat
          lon
        }
        minThreshold
        name
        orders
        organizer {
          email
          id
          logo
          name
          phoneNumber
        }
        pictures
        price
        reviewsCount
        rules
        services
        slots {
          categoryId
          days
          id
        }
        terms
        title
        typeId
        unavailableAt
      }
      nextPage
      totalCategories
    }
  }
`;


export const GET_SINGLE_CATEGORY_BY_ID = gql`
query GetCategoryById($input: category) {
  getCategoryById(input: $input) {
    id
    name
    location {
      lat
      lon
      city
      address
      country
    }
    pictures
    description
    services
    price
    reviewsCount
    title
    rules
    terms
    slots {
      id
      categoryId
      days
    }
    organizer {
      id
      name
      email
      logo
      phoneNumber
    }
    orders
    availableAt
    distance
    activityId
    typeId
    minThreshold
    capacity
    unavailableAt
    averageRating
  }

}`;

export const GET_ALL_ORGANIZERS = gql`
  query GetAllOrganizers($input: PaginationInput) {
    getAllOrganizers(input: $input) {
      nextPage
      totalOrganizers
      organizers {
        id
        name
        email
        phoneNumber
        logo
      }
    }
  }
`;


export const GET_ALL_ROLLS  = gql`
query Query {
  getAllRoles
}`


export const GET_ORDER_BY_ID  = gql`
query GetOrdersByUserId($input: userOrdersInput!) {
  getOrdersByUserId(input: $input) {
    total
    nextPage
    order {
      id
      placedAt
      team {
        name
        email
        profilePic
      }
      distance
      userId
      days {
        day
        startTime
        endTime
        bookingDate
      }
      category {
        id
        name
      }
    }
  }
}`
