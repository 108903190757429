/* eslint-disable */

import { gql } from "@apollo/client";
export const REGISTER = gql`
  mutation Mutation($input: register!) {
    register(input: $input) {
      data
      message
    }
  }
`;

export const LOGIN = gql`
  mutation Login($input: login!) {
    login(input: $input) {
      data
      message
    }
  }
`;

//forget password
export const RESET = gql`
mutation Mutation($input: forget!) {
  forgetPassword(input: $input) {
    data
    message
  }
}
`

export const OTP = gql`
mutation VerifyCode($input: verify!) {
  verifyCode(input: $input) {
    data
    message
  }
}
`


export const NEW_PASSWORD = gql`
mutation Mutation($input: login!) {
  resetPassword(input: $input) {
    message
  }
}`

// ** Category && Channel
export const CATEGORY = gql`
  mutation CreateCategory($input: createCategory!) {
    createCategory(input: $input) {
      message
    }
  }
`;





export const UPDATE_ADMIN_PROFILE = gql`
mutation Mutation($input: update!, $profilePic: Upload) {
  updateUser(input: $input, profilePic: $profilePic) {
    data
    message
  }
}`

export const ACTIVE_USER = gql`
mutation UpdateUserById($input: updateData!, $profilePic: Upload) {
  updateUserById(input: $input, profilePic: $profilePic) {
    data
    message
  }
}`

export const CREATE_ACTIVITY_TYPE = gql`

mutation Mutation($input: createActivityType!) {
  createActivityType(input: $input) {
    message
  }
}`


export const DELETE_ACTIVITY_TYPE = gql`
mutation DeleteActivityType($input: deleteActivityType!) {
  deleteActivityType(input: $input) {
    message
  }
}`

export const UPDATE_ACTIVITY_TYPE = gql`
mutation Mutation($input: updateActivityType!) {
  updateActivityType(input: $input) {
    message
  }
}
`;

export const ADD_ACTIVITY_TYPE = gql`
mutation Mutation($picture: [Upload!]!, $featuredImage: Upload!, $input: createActivity!) {
  createActivity(picture: $picture, featuredImage: $featuredImage, input: $input) {
    message
  }
}
`

export const DELETE_ACTIVITY = gql`
mutation DeleteActivity($input: deleteActivity!) {
  deleteActivity(input: $input) {
    message
  }
}`

export const UPDATE_ACTIVITY = gql`
mutation Mutation($picture: [Upload], $removePictures:[String],   $featuredImage: Upload, $input: updateActivity) {
  updateActivity(picture: $picture, removePictures : $removePictures, featuredImage: $featuredImage, input: $input) {
    data
    message
  }
}`

export const ADD_ACTIVITY = gql`
mutation Mutation($pictures: [Upload!]!, $input: AddCategoryInput) {
  createCategory(pictures: $pictures, input: $input) {
    message
  }

}`;


export const DELETE_ACTIVITY_ = gql`
mutation Mutation($input: deleteCategory!) {
  deleteCategory(input: $input) {
    message
  }
}`


export const UPDATE_ACTIVITY_ = gql`
  mutation UpdateCategory($pictures: [Upload], $removePictures:[String], $input: updateEntity) {
    updateCategory(pictures: $pictures, removePictures : $removePictures, input: $input) {
      data
      message
    }
  }
`;


export const REFRESH_TOKEN = gql`
mutation RefreshToken($input: refreshTokenInput) {
  refreshToken(input: $input) {
    message
    data {
      accessToken
      refreshToken
    }
  }
}`

export const CREATE_ORGANIZER = gql`
mutation Mutation($logo: Upload!, $input: addOrganizerInput) {
  createOrganizer(logo: $logo, input: $input) {
    message
  }
}`


export const UPDATE_ORGANIZER = gql`
mutation Mutation($logo: Upload, $input: updateOrganizer) {
  updateOrganizer(logo: $logo, input: $input) {
    data
    message
  }
}`

export const DELETE_ORGANIZER = gql`
mutation DeleteOrganizer($input: deleteOrganizer!) {
  deleteOrganizer(input: $input) {
    message
  }
}`

export const UPDATE_USER_STATUS = gql`
mutation ModifyUserStatus($modifyUserStatusId: Int!) {
  modifyUserStatus(id: $modifyUserStatusId) {
    message
  }
}`

export const UPDATE_ACTIVITY_TYPE_STATUS = gql`
mutation ModifyActivityTypeStatus($activityTypeId: Int!) {
  modifyActivityTypeStatus(activityTypeId: $activityTypeId) {
    message
  }
}`