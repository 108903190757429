/*eslint-disable */

import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Organizer from "../../views/Organizer";
//import ActivityType from "../../views/ActivitesTypes";



const OrganizerRoutes = [
  {
    path: "/organizer/list",
    element: <Organizer />,
  },
];

export default OrganizerRoutes;
