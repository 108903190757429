/* eslint-disable */
/* eslint-disable  */

// ** React Imports
import { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// ** Redux Imports
import { store } from "./redux/store";
import { Provider } from "react-redux";

// ** Intl, CASL & ThemeColors Context
import ability from "./configs/acl/ability";
import { AbilityContext } from "./utility/context/Can";
import { ThemeContext } from "./utility/context/ThemeColors";

// ** ThemeConfig
import themeConfig from "./configs/themeConfig";

// ** Toast
import { Toaster } from "react-hot-toast";

// ** i18n
import "./configs/i18n";

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Hot Toast Styles
import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";

import toast from "react-hot-toast";

// ** Apollo Client Imports
import { setContext } from "@apollo/link-context";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  useMutation,
} from "@apollo/client";


// ** Service Worker
import * as serviceWorker from "./serviceWorker";
import { REFRESH_TOKEN } from "./graphql/mutation";
import { RefreshTokenOnSubmit } from "./graphql/api-callings";
let errorProcessed = false;

const useRefreshToken = () => {
  const [refreshToken, { loading, error, data }] = useMutation(REFRESH_TOKEN);

  return {
    refreshToken,
    loading,
    error,
    data,
  };
};


// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

const container = document.getElementById("root");
const root = createRoot(container);

// ** GraphQl Connect With ApolloClient
const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_BASEURL,
  credentials: "same-origin",

});


 const removeSlashesAndQuotes = (str) => {
   // Remove backslashes and double quotes
   return str.replace(/["\\]/g, "");
 };


// Create the auth link to include the token in the headers
const authLink = setContext(  (_, { headers }) => {
  const token = localStorage.getItem("accessToken") || null;


  return {
    headers: {
      ...headers,
      authorization: token ? removeSlashesAndQuotes(token)  : null,
    },
  };
});


const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (!errorProcessed) {
    errorProcessed = true;

    if (graphQLErrors) {

        graphQLErrors.forEach(({ message, locations, path }) => {
          toast.error(`${message}`, {
            theme: "dark",
          });

          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        });


    }

    if (networkError) {
      toast.error(`[Network error]: ${networkError.message}`, {
        theme: "dark",
      });
      console.error(`[Network error]: ${networkError}`);
    }

    // Reset the flag after a short delay to allow handling new errors
    setTimeout(() => {
      errorProcessed = false;
    }, 500); // Adjust the timeout as needed
  }
})
// Combine the upload link and auth link using ApolloLink
const link = ApolloLink.from([authLink, errorLink, uploadLink]);

// Initialize the Apollo Client
const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
});

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <AbilityContext.Provider value={ability}>
          <ThemeContext>
            <ApolloProvider client={client}>
              <LazyApp />
            </ApolloProvider>
            <Toaster
              position={themeConfig?.layout?.toastPosition}
              toastOptions={{ className: "react-hot-toast" }}
            />
          </ThemeContext>
        </AbilityContext.Provider>
      </Suspense>
    </Provider>
  </BrowserRouter>
);

serviceWorker.unregister()
