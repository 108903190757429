/* eslint-disable */

// ** User List Component
import Table from './Table'
import './index.css'
import Avatar from "./../../../../assets/images/avatars/avatar-blank.png";
// ** Reactstrap Imports
import { Row, Col, Modal, ModalHeader, ModalBody, Button, Label } from 'reactstrap'

// ** Custom Components
import StatsHorizontal from '@components/widgets/stats/StatsHorizontal'
import { GET_ALL_ROLLS, GET_ALL_USERS, GET_USER_BY_ID } from "../../../../graphql/query/query";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
// ** Icons Imports
import { User, UserPlus, UserCheck, UserX } from 'react-feather'

// ** Styles
import '@styles/react/apps/app-users.scss'
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { ACTIVE_USER, REFRESH_TOKEN, UPDATE_USER_STATUS } from '../../../../graphql/mutation';
import { ActiveOnSubmit, RefreshTokenOnSubmit } from '../../../../graphql/api-callings';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';


const UsersList = () => {
   const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [page , setPage ] =useState(1)
    const [currentID , setCurrentID ] =useState(null)
     const [active  , setActive ] =useState(null)
     const [ role , setRole ] = useState(null)
     const [ status , setStatus ] = useState(null)
     const [remove , setRemove] = useState(false)


     const [refreshToken, { loadingReFressh, errorReFressh, dataReFressh }] =
       useMutation(REFRESH_TOKEN)

 const { loading: loading2, data: data2, refetch: refetch2 } = useQuery(GET_ALL_ROLLS)


 const handleSetRoles =  async (   ) => {
var formattedOptions = await data2?.getAllRoles?.map((item) => ({
  value: item?.id,
  label: item?.role,
}));


await formattedOptions?.push({
  value: null,
  label: "All",
});

await formattedOptions?.reverse();

setRole(formattedOptions);

 }
useEffect(()=>{

if(data2){

handleSetRoles()

}

},[data2])
console.log(role, "what is this");

const handleQueryError =  (error , reft ) => {
  const count = 1;
  if (error?.graphQLErrors[0]?.statusCode == 401 && count === 1) {
    const payload = {
      refreshToken: localStorage.getItem("refreshToken"),
    };

    RefreshTokenOnSubmit(payload, refreshToken, reft)
    count++;
    console.log("call refresh");
  }else {
     console.log(error?.graphQLErrors[0]?.message,'asfsaf')
  }
}

const [getAllUsers, { loading , data, refetch }] = useLazyQuery(GET_ALL_USERS, {
  variables: {
    input: {
      limit: 10,
      pageStart: page - 1,
      roleId: null,
      status: null,
      fullname: null,
    },
  },
 onError: (error) => handleQueryError(error , refetch),
});

const [
  getUserById,
  { loading: loading1, error: error1, data: data1, refetch: refetch1 },
] = useLazyQuery(GET_USER_BY_ID, {
  variables: {
    input: {
      id: currentID,
    },
  },
  onError: (error) => handleQueryError(error, refetch1),
});


const handleFilter = ( data ) => {
//data.preventDefault()
console.log(data , 'what is this')
getAllUsers({
  variables: {
    input: {
      limit: 10,
      pageStart: page - 1,
      roleId: data?.role?.value,
      status: data?.status?.value,
      fullname: data?.name,
    },
  },
});
//setRemove(true);
}

// const [UpdateUserById , { loading: loading3, error: errror3, data: data3 }] =
//   useMutation(ACTIVE_USER)


   const [
     modifyUserStatus,
     { loading: loading3, error: errror3, data: data3 },
   ] = useMutation(UPDATE_USER_STATUS);

// useEffect(()=>{

//    if(modal){
//   if(currentID){

//   getUserById({
//     input: { currentID }
//   })
// }
//    }


// },[currentID,modal])


function formatDob(dob){

  const date = new Date(dob);
  const options = { year: "numeric", month: "long", day: "numeric" };
  const readableDate = date.toLocaleDateString(undefined, options);
  return readableDate
}

function formatDate(isoString) {
  const date = new Date( isoString );

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }

  return date.toLocaleString('en-US', options);
}

const handleinActiveHit = (status , sid) => {
  Swal.fire({
    title: "Are you sure?",
    text: status
      ? "You want to deActivate this user!"
      : "You want to Activate this user!",

    showCancelButton: true,
    confirmButtonColor: status ? "#d33" : "#22bb33",
    cancelButtonColor: "#d33",
    cancelButtonMarginleft: "10px",
    confirmButtonText: status ? "DeActivate" : "Activate",
  }).then((result) => {
    if (result?.isConfirmed) {

        console.log(status, "status");
        const payload = {
          modifyUserStatusId: parseInt(sid),
        };
        ActiveOnSubmit(payload, modifyUserStatus, refetch);

      console.log(status, "status2");
    }
  });
}

const handleInActiveUser = async(id) => {
   setCurrentID(id)

  console.log(id,'what is this')

}

console.log(data1,'hehehe')

useEffect(() => {
    if(page!==1){
      refetch()
    }
    refetch()
        console.log(page)
    },[page])


   console.log(data , "data");
   const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <div className="app-user-list">
      {/* <Row>
        <Col lg="3" sm="6">
          <StatsHorizontal
            color="primary"
            statTitle="Total Users"
            icon={<User size={20} />}
            renderStats={<h3 className="fw-bolder mb-75">2</h3>}
          />
        </Col>
        <Col lg="3" sm="6">
          <StatsHorizontal
            color="danger"
            statTitle="Paid Users"
            icon={<UserPlus size={20} />}
            renderStats={<h3 className="fw-bolder mb-75">4</h3>}
          />
        </Col>
        <Col lg="3" sm="6">
          <StatsHorizontal
            color="success"
            statTitle="Active Users"
            icon={<UserCheck size={20} />}
            renderStats={<h3 className="fw-bolder mb-75">19</h3>}
          />
        </Col>
        <Col lg="3" sm="6">
          <StatsHorizontal
            color="warning"
            statTitle="Pending Users"
            icon={<UserX size={20} />}
            renderStats={<h3 className="fw-bolder mb-75">23</h3>}
          />
        </Col>
      </Row> */}

      <Table
        refectAllUsers={refetch}
        active={handleinActiveHit}
        remove={remove}
        setRemove={setRemove}
        handleFilter={handleFilter}
        toggle={toggle}
        formattedOptions={role}
        handleActive={handleInActiveUser}
        setSidebarOpen={setSidebarOpen}
        sidebarOpen={sidebarOpen}
        role={data2}
        data={data}
        setPage={setPage}
        page={page}
      />

      {Avatar && (
        <>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader
              style={{ height: "90PX" }}
              toggle={toggle}
            ></ModalHeader>
            <ModalBody className={"px-3"} style={{ boxSizing: "border-box" }}>
              <Row>
                <Col md="6">
                  <img
                    src={
                      data1?.getUserById?.profilePic
                        ? `${process.env.REACT_APP_OUT_URL}${data1?.getUserById?.profilePic}`
                        : Avatar && Avatar
                    }
                    className=""
                    style={{
                      marginTop: "-65px",
                      marginLeft: "10px",
                      height: "100px",
                      width: "100px",
                      backgroundColor: "transparent",
                      borderRadius: "50%",
                      border: "4px solid #fff",
                    }}
                  />
                </Col>
                <Col md="6">
                  <div className="mt-1 d-flex justify-content-end">
                    <Button
                      disabled={loading3}
                      className="add-new-user"
                      color=""
                      style={{
                        border: data1?.getUserById?.status
                          ? "2px solid #d33"
                          : "2px solid #22bb33",
                        color: data1?.getUserById?.status ? "#d33" : "#22bb33",
                      }}
                      onClick={() =>
                        handleinActiveHit(
                          data1?.getUserById?.status,
                          data1?.getUserById?.id
                        )
                      }
                    >
                      { data1?.getUserById?.status
                        ? "In-Active This User"
                        : "Active This User"}
                    </Button>
                  </div>
                </Col>
                <hr className="my-2 " />
              </Row>
              <Row>
                <Col md="4">
                  <Label style={{ color: "#000", fontWeight: "600" }}>
                    Name
                  </Label>
                </Col>
                <Col md="8">
                  <Label
                    style={{
                      color: "#7367f0",
                      fontWeight: "600",
                      overflowWrap: "anywhere",
                      boxSizing: "borderBox",
                    }}
                  >
                    { data1?.getUserById?.fullname
                      ? data1?.getUserById?.fullname
                      : "------"}
                  </Label>
                </Col>
                <hr className="my-2" />
              </Row>

              <Row>
                <Col md="4">
                  <Label style={{ color: "#000", fontWeight: "600" }}>
                    Email
                  </Label>
                </Col>
                <Col md="8">
                  <Label style={{ color: "#7367f0", fontWeight: "600" }}>
                    {data1?.getUserById?.email
                      ? data1?.getUserById?.email
                      : "------"}
                  </Label>
                </Col>
                <hr className="my-2" />
              </Row>

              <Row>
                <Col md="4">
                  <Label style={{ color: "#000", fontWeight: "600" }}>
                    Phone
                  </Label>
                </Col>
                <Col md="8">
                  <Label
                    style={{
                      color: "#7367f0",
                      fontWeight: "600",
                      overflowWrap: "anywhere",
                      boxSizing: "borderBox",
                    }}
                  >
                    {data1?.getUserById?.phoneNumber
                      ? data1?.getUserById?.phoneNumber
                      : "------"}
                  </Label>
                </Col>
                <hr className="my-2" />
              </Row>

              <Row>
                <Col md="4">
                  <Label style={{ color: "#000", fontWeight: "600" }}>
                    Date of Birth
                  </Label>
                </Col>
                <Col md="8">
                  <Label style={{ color: "#7367f0", fontWeight: "600" }}>
                    {data1?.getUserById?.dob
                      ? formatDob(data1?.getUserById?.dob)
                      : "------"}
                  </Label>
                </Col>
                <hr className="my-2" />
              </Row>

              <Row>
                <Col
                  md="4"
                  className={{
                    overflowWrap: "anywhere",
                    boxSizing: "borderBox",
                  }}
                >
                  <Label style={{ color: "#000", fontWeight: "600" }}>
                    Bio
                  </Label>
                </Col>
                <Col md="8">
                  <Label
                    style={{
                      color: "#7367f0",
                      fontWeight: "600",
                      overflowWrap: "anywhere",
                      boxSizing: "borderBox",
                    }}
                  >
                    {data1?.getUserById?.aboutMe
                      ? data1?.getUserById?.aboutMe
                      : "------"}
                  </Label>
                </Col>
                <hr className="my-2" />
              </Row>

              <Row>
                <Col
                  md="4"
                  className={{
                    overflowWrap: "anywhere",
                    boxSizing: "borderBox",
                  }}
                >
                  <Label style={{ color: "#000", fontWeight: "600" }}>
                    Interest
                  </Label>
                </Col>
                <Col md="8">
                  {data1?.getUserById?.interests &&
                    data1?.getUserById?.interests?.map((item) => {
                      return (
                        <>
                          <Label
                            style={{
                              color: "#7367f0",
                              fontWeight: "600",
                              overflowWrap: "anywhere",
                              boxSizing: "borderBox",
                            }}
                          >
                            {item?.interest ? item?.interest : "-----"}
                          </Label>
                        </>
                      );
                    })}
                </Col>
                <hr className="my-2" />
              </Row>

              <Row className={"mb-1"}>
                <Col md="4">
                  <Label style={{ color: "#000", fontWeight: "600" }}>
                    Bookings
                  </Label>
                </Col>
                <Col md="8">
                  <div
                    style={{
                      color: "#7367f0",
                      fontWeight: "600",
                      overflowWrap: "anywhere",
                      boxSizing: "borderBox",
                    }}
                  >
                    {data1?.getUserById?.bookings
                      ? data1?.getUserById?.bookings?.map((item) => {
                          return (
                            <>
                              <div className="d-flex justify-content-between">
                                <Label style={{ fontSize: "10px" }}>
                                  {item?.name}
                                </Label>
                                <Label style={{ fontSize: "10px" }}>
                                  {formatDate(item?.bookingDate)}
                                </Label>
                              </div>
                            </>
                          );
                        })
                      : "-----"}
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </>
      )}
    </div>
  );
}

export default UsersList
