/*eslint-disable */
import * as yup from "yup";

export const loginSchema = yup.object().shape({
  email: yup.string().email().required("Please enter a email"),
  password: yup
    .string()
    .min(8,'minimum 8 characters required')
    .max(20)
    .required("Please enter a password"),
});

export const newPasswordSchema = yup.object().shape({
  new_password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .max(10, "Password must not exceed 10 characters")
    .required("Please enter a valid password!"),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("new_password"), null], "Passwords must match")
    .required("Please confirm your password"),
});

export const resetSchema = yup.object().shape({
  email: yup.string().email().required("Please enter a email!"),
});

export const otpSchema = yup.object().shape({
  otp: yup
    .string()
    .matches(/^\d{4}$/, "OTP must be exactly 4 digits")
    .required("Please enter a valid OTP"),
});


export const addActivity = yup.object().shape({
  activityType: yup.string().required("Please enter a Category Name."),
});

export const addActivityType = yup.object().shape({
  activity: yup.string().required("Please enter a Activity Type."),


});

export const addCategorySchema = yup.object().shape({
  name: yup.string().required("Please enter Activity Name."),
  // orgName: yup.string().required("Please Organizer Name."),
  // orgNumber: yup
  //   .string()
  //   .required("Please Organizer Phone Number."),

 // orgEmail: yup.string().email().required("Please enter a Organizer email!"),
  price: yup
    .number("")
    .typeError("Value must be a number")
    .required("Please enter a valid price."),
  // rating: yup
  //   .number("")
  //   .typeError("Value must be a number")
  //   .test(
  //     "is-decimal",
  //     "Invalid float value",
  //     (value) => value !== undefined && (value + "").match(/^\d*\.?\d*$/)
  //   )
  //   .required("This field is required"),
  capacity: yup
    .number("")
    .required("Please enter a Capacity.")
    .typeError("Value must be a number"),

  address: yup.string().required("Please enter Address."),
  city: yup.string().required("Please enter City Name."),
  country: yup
    .string()
    .typeError("Value must be a Alphabets")
    .required("Please enter Country Name."),
  lat: yup
    .number()
    .typeError("Value must be a number")
    .test(
      "is-decimal",
      "Invalid float value",
      (value) => value !== undefined && (value + "").match(/^\d*\.?\d*$/)
    )
    .required("This field is required"),
  lon: yup
    .number()
    .typeError("Value must be a number")
    .test(
      "is-decimal",
      "Invalid float value",
      (value) => value !== undefined && (value + "").match(/^\d*\.?\d*$/)
    )
    .required("This field is required"),
  // minThreshold: yup
  //   .number("")
  //   .typeError("Value must be a number")
  //   .required("Please minThreshold a value."),
  // OrImage: yup.mixed(),
  description: yup.string().required("Please enter Description."),
  rules: yup.string().required("Please enter Rules."),
  term: yup.string().required("Please enter Terms."),
//  organId: yup.string().required("Please Select Organizer id."),
  //  unavailableAt: yup.string().required("Please Unavailible Date."),
});

export const newPassSchema = yup.object().shape({
  new_password: yup
    .string()
    .min(8, "Password must be at least 6 characters")
    .max(15, "Password must not exceed 15 characters")
    .required("Please enter a valid password!"),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("new_password"), null], "Passwords must match")
    .required("Please confirm your password"),
});

export const AddOrganizer = yup.object().shape({
  name: yup.string().required("Please enter a Organizer!"),
  email: yup.string().email().required("Please enter a Organizer email!"),
  phone: yup.string().required("Please enter PhoneNumber!"),

})