// ** React Imports
/*eslint-disable */
import { lazy } from 'react'

const Login = lazy(() => import('../../views/pages/authentication/Login'))
const ResetPassword = lazy(() => import('../../views/pages/authentication/resetPassword'))
const LoginCover = lazy(() => import('../../views/pages/authentication/LoginCover'))

const Register = lazy(() => import('../../views/pages/authentication/Register'))
const RegisterBasic = lazy(() => import('../../views/pages/authentication/RegisterBasic'))
const RegisterCover = lazy(() => import('../../views/pages/authentication/RegisterCover'))
const RegisterMultiSteps = lazy(() => import('../../views/pages/authentication/register-multi-steps'))

const ForgotPassword = lazy(() => import('../../views/pages/authentication/ForgotPassword'))
const ForgotPasswordBasic = lazy(() => import('../../views/pages/authentication/ForgotPasswordBasic'))
const ForgotPasswordCover = lazy(() => import('../../views/pages/authentication/ForgotPasswordCover'))

const ResetPasswordBasic = lazy(() => import('../../views/pages/authentication/ResetPasswordBasic'))
const ResetPasswordCover = lazy(() => import('../../views/pages/authentication/ResetPasswordCover'))

const VerifyEmailBasic = lazy(() => import('../../views/pages/authentication/VerifyEmailBasic'))
const VerifyEmailCover = lazy(() => import('../../views/pages/authentication/VerifyEmailCover'))

const TwoStepsBasic = lazy(() => import('../../views/pages/authentication/TwoStepsBasic'))
const TwoStepsCover = lazy(() => import('../../views/pages/authentication/TwoStepsCover'))

const OtpPassword = lazy(() =>
  import("../../views/pages/authentication/otpPassword")
);

const AuthenticationRoutes = [
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    layout: "BlankLayout",
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true,
    },
  },
  {
    path: "/otp",
    element: <OtpPassword />,
    layout: "BlankLayout",
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true,
    },
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
    layout: "BlankLayout",
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true,
    },
  },

  {
    path: "/pages/forgot-password-basic",
    element: <ForgotPasswordBasic />,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/pages/forgot-password-cover",
    element: <ForgotPasswordCover />,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/pages/reset-password-basic",
    element: <ResetPasswordBasic />,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/pages/reset-password-cover",
    element: <ResetPasswordCover />,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/pages/verify-email-basic",
    element: <VerifyEmailBasic />,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/pages/two-steps-basic",
    element: <TwoStepsBasic />,
    meta: {
      layout: "blank",
    },
  },
];

export default AuthenticationRoutes
