// ** Icons Import
/* eslint-disable comma-dangle */
/*eslint-disable */
import { FileText, Circle, User, DollarSign, Globe, Users } from "react-feather"

export default [
  {
    header: "Menu",
  },
  {
    id: "users",
    title: "Users",
    icon: <Users size={20} />,
    navLink: "/user/list",
  },

  {
    id: "categories",
    title: "Categories",
    icon: <FileText size={20} />,
    navLink: "/category/list",
  },

  {
    id: "organizer",
    title: "Organizers",
    icon: <Globe size={20} />,
    navLink: "/organizer/list",
  },
  // {
  //   id: "pricing",
  //   title: "Pricing",
  //   icon: <DollarSign size={20} />,
  //   navLink: "pages/pricing",
  // },
  // {
  //   id: 'roles-permissions',
  //   title: 'Roles & Permissions',
  //   icon: <Shield size={20} />,
  //   children: [
  //     {
  //       id: 'roles',
  //       title: 'Roles',
  //       icon: <Circle size={12} />,
  //       navLink: '/apps/roles'
  //     },
  //     {
  //       id: 'permissions',
  //       title: 'Permissions',
  //       icon: <Circle size={12} />,
  //       navLink: '/apps/permissions'
  //     }
  //   ]
  // }
];
