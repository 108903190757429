/* eslint-disable no-unused-vars */
/* eslint-disable */

// ** React Imports
import { useState, Fragment } from "react";

// ** Reactstrap Imports
import {
  Row,
  Col,
  Card,
  Form,
  CardBody,
  Button,
  Badge,
  Modal,
  Input,
  Label,
  ModalBody,
  ModalHeader,
} from "reactstrap";

// ** Third Party Components
import Swal from "sweetalert2";
import Select from "react-select";
import { Check, Briefcase, X } from "react-feather";
import { useForm, Controller } from "react-hook-form";
import withReactContent from "sweetalert2-react-content";

// ** Custom Components
import Avatar from "@components/avatar";

// ** Utils
import { selectThemeColors } from "@utils";

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";
import { useNavigate } from "react-router-dom";
import { GET_USER_BY_ID } from "../../../../graphql/query/query";
import { formatDate } from "../../../../utility/Utils";

const roleColors = {
  editor: "light-info",
  admin: "light-danger",
  author: "light-warning",
  maintainer: "light-success",
  subscriber: "light-primary",
};

const statusColors = {
  active: "light-success",
  pending: "light-warning",
  inactive: "light-secondary",
};

const statusOptions = [
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" }
];

const countryOptions = [
  { value: "uk", label: "UK" },
  { value: "usa", label: "USA" },
  { value: "france", label: "France" },
  { value: "russia", label: "Russia" },
  { value: "canada", label: "Canada" },
];

const languageOptions = [
  { value: "english", label: "English" },
  { value: "spanish", label: "Spanish" },
  { value: "french", label: "French" },
  { value: "german", label: "German" },
  { value: "dutch", label: "Dutch" },
];

const MySwal = withReactContent(Swal);


const UserInfoCard = ({ selectedUser }) => {
  // ** State
  const [show, setShow] = useState(false);

  // ** Hook


  // ** render user img
  const renderUserImg = ( url ) => {
    if ( selectedUser?.type == 'email') {
      return (
        <img
          height="110"
          width="110"
          alt="user-avatar"
          src={`${process.env.REACT_APP_OUT_URL}${selectedUser?.profilePic}`}
          className="img-fluid rounded mt-3 mb-2"
        />
      );
    }else if (
      selectedUser?.type == "google" ||
      selectedUser?.type == "apple" ||
      selectedUser?.type == "facebook"
    ) {
  return (
    <img
      height="110"
      width="110"
      alt="user-avatar"
      src={selectedUser?.profilePic}
      className="img-fluid rounded mt-3 mb-2"
    />
  );

    } else {
      return (
        <Avatar
          initials
          color={selectedUser?.avatarColor || "light-primary"}
          className="rounded mt-3 mb-2"
          content={selectedUser?.fullName}
          contentStyles={{
            borderRadius: 0,
            fontSize: "calc(48px)",
            width: "100%",
            height: "100%",
          }}
          style={{
            height: "110px",
            width: "110px",
          }}
        />
      );
    }
  };


  const renderDetails = ( content ) => {

    if(content?.length){
       return content
    }else{
      return '---------'
    }
  }

  const onSubmit = (data) => {




  };



  const navigate = useNavigate();
  const handleDeleteClick = () => {
    return MySwal?.fire({
      title: "Are you sure?",
      text: "You won't be able to revert user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Delete user!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1",
      },
      buttonsStyling: false,
    }).then(function (result) {
      if (result?.value) {
        MySwal.fire({
          icon: "success",
          title: "Deleted!",
          text: "User has been Deleted.",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });

        navigate("/apps/user/view");
      } else if (result?.dismiss === MySwal?.DismissReason?.cancel) {
        MySwal.fire({
          title: "Cancelled",
          text: "Cancelled Delete :)",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }
    });
  };



  return (
    <Fragment>
      <Card>
        <CardBody>
          <div className="user-avatar-section">
            <div className="d-flex align-items-center flex-column">
              {renderUserImg()}
              <div className="d-flex flex-column align-items-center text-center">
                <div className="user-info">
                  <h4>{renderDetails(selectedUser?.fullname)}</h4>
                  <Badge
                    className="text-capitalize mb-2"
                    color={
                      selectedUser?.role?.role == "admin"
                        ? "light-danger"
                        : "light-secondary"
                    }
                  >
                    {renderDetails(selectedUser?.role?.role)}
                  </Badge>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-2">
            <span className="text-capitalize">{selectedUser?.aboutMe}</span>
          </div>
          <h4 className="fw-bolder border-bottom pb-50 mb-1">Details</h4>
          <div className="info-scontainer">
            {selectedUser !== null ? (
              <ul className="list-unstyled">
                <li className="mb-75 d-flex justify-content-between">
                  <span className="fw-bolder me-25 ">Username:</span>
                  <span>{renderDetails(selectedUser?.fullname)}</span>
                </li>
                <li className="mb-75 d-flex justify-content-between">
                  <span className="fw-bolder me-25">Email:</span>
                  <span>{renderDetails(selectedUser?.email)}</span>
                </li>
                <li className="mb-75 d-flex justify-content-between">
                  <span className="fw-bolder me-25">Status:</span>
                  <Badge
                    className="text-capitalize"
                    color={
                      selectedUser?.status ? "light-success" : "light-secondary"
                    }
                  >
                    {
                      selectedUser?.status ? "Active" : "DeActive"
                    }
                  </Badge>
                </li>

                <li className="mb-75 d-flex justify-content-between">
                  <span className="fw-bolder me-25">Dob:</span>
                  <span>{renderDetails(formatDate(selectedUser?.dob))}</span>
                </li>
                <li className="mb-75 d-flex justify-content-between">
                  <span className="fw-bolder me-25">Gender:</span>
                  <span>{renderDetails(selectedUser?.gender)}</span>
                </li>
                <li className="mb-75 d-flex justify-content-between">
                  <span className="fw-bolder me-25">Phone:</span>
                  <span>{renderDetails(selectedUser?.phoneNumber)}</span>
                </li>
                <li className="mb-75 d-flex justify-content-between">
                  <span className="fw-bolder me-25">Interests:</span>
                  <div className="d-flex gap-1 " style={{ maxWidth:'250px', flexWrap: "wrap" }}>
                    {renderDetails(
                      selectedUser?.interests?.map((item) => {
                        return (
                          <Badge
                            className="text-capitalize"
                            color={"light-warning"}
                          >
                            {renderDetails(item?.interest)}
                          </Badge>
                        );
                      })
                    )}
                  </div>
                </li>

                {/* <li className="mb-75">
                  <span className="fw-bolder me-25">Tax ID:</span>
                  <span>
                    Tax-
                    {selectedUser.contact.substr(
                      selectedUser.contact.length - 4
                    )}
                  </span>
                </li> */}
                {/* <li className="mb-75">
                  <span className="fw-bolder me-25">Contact:</span>
                  <span>{selectedUser.contact}</span>
                </li> */}
                {/* <li className="mb-75">
                  <span className="fw-bolder me-25">Language:</span>
                  <span>English</span>
                </li> */}
                {/* <li className="mb-75">
                  <span className="fw-bolder me-25">Country:</span>
                  <span>England</span>
                </li> */}
              </ul>
            ) : null}
          </div>
          <div className="d-flex justify-content-center pt-2"></div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default UserInfoCard;
