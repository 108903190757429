/*eslint-disable */

import { lazy } from "react";
import { Navigate } from "react-router-dom";
import UserDetails from "../../views/apps/user/view/UserDetails";
import UsersList from "../../views/apps/user/list";
import UserProfile from "../../views/Activites copy";

const Profile = lazy(() => import("../../views/user/profile/index"));

const UserRoutes = [
  {
    path: "/user/profile",
    element: <Profile />,
  },
  {
    path: "/user/profile",
    element: <Profile />,
  },
  {
    path: "/user/view/:id",
    element: <UserDetails />,
  },
  {
    element: <UsersList />,
    path: "/user/list",
  },
];
;

export default UserRoutes;
