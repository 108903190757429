/*eslint-disable */
import React, { Fragment, useEffect, useState } from "react";
import {
  Archive,
  FileText,
  Lock,
  MoreVertical,
  Trash2,
  User,
  Edit,
  Trash,
  Eye,
  Delete,
} from "react-feather";
import "./index.css";
import {
  Card,
  CardBody,
  CardHeader,
  NavLink,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Button,
  Modal,
  ModalHeader,
  Col,
  ModalBody,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Input,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap";

import {
  GET_ACTIVITY_BY_ID,
  GET_ALL_ORGANIZERS,
  GET_CATEGORY_BY_ID,
  GET_SINGLE_CATEGORY_BY_ID,
} from "../../graphql/query/query";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Avatar from "../components/avatar";
import DataTable from "react-data-table-component";
import {
  ADD_ACTIVITY,
  ADD_ACTIVITY_TYPE,
  DELETE_ACTIVITY,
  DELETE_ACTIVITY_,
  DELETE_ACTIVITY_TYPE,
  UPDATE_ACTIVITY,
  UPDATE_ACTIVITY_,
} from "../../graphql/mutation";
import {
  AddActivityOnSubmit,
  AddActivityTypeOnSubmit,
  CreateActivityOnSubmit,
  DeleteActivityOnSubmit,
  DeleteActivityTypeOnSubmit,
  DeleteCategoryOnSubmit,
  UpdateActivityTypeOnSubmit,
  UpdateCategoryOnSubmit,
} from "../../graphql/api-callings";
import { Controller, set, useForm } from "react-hook-form";
import { addActivityType, addCategorySchema } from "../../validation";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { isType } from "graphql";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import IterateUplaod from "./iterateUpload";
import { IoMdCloseCircle } from "react-icons/io";
import IterateUploadActivity from "./iterateUploadActivity";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import Swal from "sweetalert2";
import "react-datepicker/dist/react-datepicker.css";

export default function ActivityType() {
  const [modal, setModal] = useState(false);
  const [active, setActive] = useState("1");
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();
  const [slotsMon, setSlotsMon] = useState([]);
  const [slotsTue, setSlotsTue] = useState([]);
  const [slotsWed, setSlotsWed] = useState([]);
  const [slotsThur, setSlotsThur] = useState([]);
  const [slotsFri, setSlotsFri] = useState([]);
  const [slotsSat, setSlotsSat] = useState([]);
  const [slotsSun, setSlotsSun] = useState([]);
  const [slotsError, setSlotsError] = useState([]);
  const [preImages, setPreImages] = useState([]);
  const [editImage , setEditImage] = useState(null)
  const [imageLink , setImageLink] = useState(null)


  const [errorFeatureType, setErrorFeatureType] = useState(null);
  const [errorOr, setErrorOr] = useState(null);
  const [errorService, setErrorService] = useState(null);
  const [catError, setCatError] = useState(null);
  const [ImageError, setImageError] = useState(null);

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [startTimeM, setStartTimeM] = useState("");
  const [endTimeM, setEndTimeM] = useState("");
  const [startTimeT, setStartTimeT] = useState("");
  const [endTimeT, setEndTimeT] = useState("");
  const [startTimeW, setStartTimeW] = useState("");
  const [endTimeW, setEndTimeW] = useState("");
  const [startTimeR, setStartTimeR] = useState("");
  const [endTimeR, setEndTimeR] = useState("");
  const [startTimeF, setStartTimeF] = useState("");
  const [endTimeF, setEndTimeF] = useState("");
  const [startTimeS, setStartTimeS] = useState("");
  const [endTimeS, setEndTimeS] = useState("");
  const [startTimeN, setStartTimeN] = useState("");
  const [endTimeN, setEndTimeN] = useState("");

  const [ActivityTypeId, setActivityTypeId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [organId, setOrganId] = useState(null);

  const [typeFeatureImage, setTypeFeatureImage] = useState(null);
  const [OrImage, setOrImage] = useState(null);
  const [typeImages, setTypeImages] = useState([]);
  const [services, setServices] = useState([]);
  const [service, setService] = useState("");
  const [detail, setDetail] = useState(false);
  const [slotId, setSlotId] = useState(null);
  const [currentCategory, setCurrentCategory] = useState();

  const [page, setPage] = useState(1);
  const params = useParams()
  const location = useLocation()
  var typeImagesObj = []
  var typeImagesArray = []
  var singleFileObj = []
  var singleFileArray = []
  const [singleFile, setSingleFile] = useState([]);
  const [open, setOpen] = useState("1");
  const [days, setDays] = useState([]);
  const [remove ,  setRemove ] = useState([])

  const toggle5 = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  console.log(days, "asfasf");



  //const [multipleFile, setMultipleFile] = useState([]);
  const {
    loading: loadingOr,
    error: errorOrr,
    data: dataOr,
    refetch: refechOr,
  } = useQuery(GET_ALL_ORGANIZERS,{
    variables:{
  input: {
      limit: 100,
      pageStart: 0,
    },
    }
  });

  const [createActivity, { loading: loading1, error: errror1, data: data1 }] =
    useMutation(ADD_ACTIVITY_TYPE);

  const [createCategory, { loading: loading4, error: errror4, data: data4 }] =
    useMutation(ADD_ACTIVITY);

  const [deleteActivity, { loading: loading2, error: errror2, data: data2 }] =
    useMutation(DELETE_ACTIVITY);

  const [updateActivity, { loading: loading3, error: errror3, data: data3 }] =
    useMutation(UPDATE_ACTIVITY);

  const [updateCategory, { loading: loading6, error: errror6, data: data6 }] =
    useMutation(UPDATE_ACTIVITY_);

  const [
    getCategoryById,
    { loading: loading8, error: error8, data: data8, refetch: refetch8 },
  ] = useLazyQuery(GET_SINGLE_CATEGORY_BY_ID);

  const toggleTab = (tab) => {
    if (active !== tab) {
      setActive(tab);
    }
  };

  const [deleteCategory, { loading: loading5, error: errror5, data: data5 }] =
    useMutation(DELETE_ACTIVITY_);

  function parseTime(time) {
    const [hours, minutes] = time?.split(":").map(Number);
    return hours * 60 + minutes;
  }

  function convertTo12HourFormat(time) {
    // Split the time string into hours and minutes
    let [hours, minutes] = time?.split(":")?.map(Number);

    // Determine if it's AM or PM
    let period = hours >= 12 ? "PM" : "AM";

    // Adjust hours to 12-hour format
    hours = hours % 12 || 12;

    // Return the formatted time
    return `${hours}:${minutes?.toString().padStart(2, "0")} ${period}`;
  }

  function isOverlapping(newSlot, slots) {
    const newStart = parseTime(newSlot.startTime);
    const newEnd = parseTime(newSlot.endTime);

    for (const slot of slots) {
      const start = parseTime(slot.startTime);
      const end = parseTime(slot.endTime);

      if (
        (newStart >= start && newStart < end) ||
        (newEnd > start && newEnd <= end) ||
        (newStart <= start && newEnd >= end)
      ) {
        return true;
      }
    }

    return false;
  }



  function addSlot(newSlot, slots, event, start, end , setSlots) {
    if (isOverlapping(newSlot, slots)) {
      console.log("This time slot already exists.");
      Swal.fire({
        title: "Information",
        text: "This time slot already exists.",
      });
      // event.preventDefault()
    } else if (start == end) {
      Swal.fire({
        title: "Information",
        text: "Start time and end time cannot be the same.",
      });
      console.log(slots, "asfsaf");
      // event.preventDefault();
    } else {
      // event.preventDefault()
      setSlots([...slots, newSlot]);
      days?.push(newSlot);
      console.log("New slot added successfully.");
      setSlotsError(null);
    }
  }

  const createSlots = (event, slot, start, end, day , setSlot) => {
    if (!start?.length || !end?.length) {
      Swal?.fire({
        title: "Information",
        text: "Please select startDate and EndDate.",
      });
      //event.preventDefault()
    } else {
      const newSlot = { startTime: start, endTime: end, day: day };
      addSlot(newSlot, slot, event, start, end , setSlot);
    }
  };

  const {
    control,
    setValue,
    setError,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      location?.state?.type == "activity" ? addActivityType : addCategorySchema
    ),
  });

  const formattedOptions = dataOr?.getAllOrganizers?.organizers?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));
//currrrr
  const handleDeleteSlots = (item , set , value , index) => {
       set([
      ...value?.slice(0, index),
      ...value?.slice(index + 1, index?.length),
    ]);
     days?.map((filItem , ind )=> {

        if( filItem?.startTime == item?.startTime && filItem?.endTime == item?.endTime &&  filItem?.day == item?.day ){
             setDays([
              ...days?.slice(0, ind),
              ...days?.slice(ind + 1, ind?.length),
             ])
        }

      })

    // console.log(inD,'innndex')
      console.log(days,'innndexDays')

  }
 console.log(days, "innndexDays");
  console.log(typeImages, "currentIAGS ");
  console.log(typeFeatureImage, "currentIAG");

  const toggle = () => setModal(!modal);
  const toggle1 = () => setDetail(!detail);

  const handleDeleteActivityType = (id) => {
    // console.log(id,'id')
    const payload = {
      activityId: parseInt(id),
    };

    DeleteActivityTypeOnSubmit(payload, deleteActivity, refetch);
  };

  const handleDeleteCategory = (id) => {
    const payload = {
      categoryId: parseInt(id),
    };

    DeleteCategoryOnSubmit(payload, deleteCategory, refetch);
  }

  const handleAddServices = (e) => {
    e.preventDefault()
    console.log(service, "console");
    setServices([...services, service]);
    setService(null);
    setErrorService(null)
  };

  const handleDeleteService = (index) => {
    if (index > -1) {
      // Create a new array without mutating the original state
      const updatedServices = [
        ...services.slice(0, index),
        ...services.slice(index + 1),
      ];
      setServices(updatedServices); // Update the state
    }
  }

  const handleEditActivity = (row) => {
    setModal(true);
    setEdit(true);
    typeImagesObj = [];
    typeImagesArray = [];
    singleFileObj = [];
    singleFileArray = [];
    setSingleFile([]);
    setTypeFeatureImage(null);
    setTypeImages([]);
    reset({
      activity: row?.name || "",
      // Ensure boolean value
    });
    setActivityTypeId(row, "oyeee");
     setRemove([])
     setPreImages(row?.picture)
 setEditImage(`${process?.env?.REACT_APP_OUT_URL}${row?.featuredImage}`);
   setImageLink(null)


  }

  const handleEditCategory = (row) => {
    console.log(row, "im hittt");

    setModal(true)
    setEdit(true)
    setSlotsMon([])
    setSlotsTue([])
    setSlotsWed([])
    setSlotsThur([])
    setSlotsFri([])
    setSlotsSat([])
    setSlotsSun([])
    typeImagesObj = [];
    typeImagesArray = [];
    singleFileObj = [];
    singleFileArray = [];
    setSingleFile([]);
    setTypeFeatureImage(null);
    setTypeImages([]);
    setOrImage([]);
    setService([]);
    setRemove([])
    setDays([])

    getCategoryById({
      variables: {
        input: {
          id: row?.id,
          lat: null,
          lon: null,
        },
      },
    });
        handleSetSlots(row?.slots?.days)
        setServices(row?.services)
    refetch8();

    //   if(row){
    //     console.log(row,'ajsfkasjf')
    //   reset({
    //        activity: data8?.getCategoryById?.name || "",
    //        capacity: data8?.getCategoryById?.capacity || "", //integer
    //        //1990-01-01T00:00:00.000Z
    //        description: data8?.getCategoryById?.description || "", //string
    //        rules:data8?.getCategoryById?.rules || "",
    //        term:data8?.getCategoryById?.terms || "",
    //        address: data8?.getCategoryById?.location?.address || "", //string
    //        city: data8?.getCategoryById?.location?.city || "", //city

    //        country: data8?.getCategoryById?.location?.country || "", //
    //        lat: data8?.getCategoryById?.location?.lat || "", //float
    //        lon: data8?.getCategoryById?.location?.lon || "", //float
    //       // availableAt: data8?.getCategoryById?.availableAt ? new Date(data8?.getCategoryById?.availableAt) : null, //date
    //       // minThreshold: data8?.getCategoryById?.minThreshold || "", //integer
    //        name: data8?.getCategoryById?.name || "", //string
    //        price: row?.getCategoryById?.price || "", //
    //       organId:{label:row?.organizer?.name , value:row?.organizer?.id }
    //        //rating: data8?.getCategoryById?.rating || "", //float
    //       // orgName: data8?.getCategoryById?.organizer[0]?.name || "",
    //       // orgNumber: data8?.getCategoryById?.organizer[0]?.phoneNumber || "",
    //       // orgEmail: data8?.getCategoryById?.organizer[0]?.email || "",
    //       // unavailableAt: data8?.getCategoryById?.unavailableAt ? new Date(data8?.getCategoryById?.unavailableAt) : null,
    //   //witch
    //        // Ensure boolean value
    //      })
    //      setCategoryId(row?.id);
    //    setOrganId( row?.organizerId
    // ?.id)
    //      setSlotId(data8?.getCategoryById?.slots?.id)

    //      }

    console.log(data8, "hammad");
  };

  const handleRemovePreImage = (removeImage) => {
    remove.push(preImages[removeImage])
    setPreImages([
      ...preImages?.slice(0, removeImage),
      ...preImages?.slice(removeImage + 1, removeImage?.length),
    ]);
 console.log(remove,'removeImages')

  };

const handleSetSlots = (SL) => {
   console.log(SL,'WHAT IS THIS')
for (const day in SL) {
    if (SL.hasOwnProperty(day)) {
        console.log(`Day: ${day}`);
        // Iterate over each time slot for the current day
        SL[day].forEach(slot => {
        console.log(slot,'safafasffs')

        if(day?.includes('Monday')){
              setSlotsMon(prevSlots => [...prevSlots, { startTime: slot.startTime, endTime: slot.endTime, day }])
              setDays(prevSlots => [...prevSlots, { startTime: slot.startTime, endTime: slot.endTime, day }])

        }
         if(day?.includes('Tuesday')){
              setSlotsTue(prevSlots => [...prevSlots, { startTime: slot.startTime, endTime: slot.endTime, day }])
        setDays(prevSlots => [...prevSlots, { startTime: slot.startTime, endTime: slot.endTime, day }])
            }
         if(day?.includes('Wednesday')){
              setSlotsWed(prevSlots => [...prevSlots, { startTime: slot.startTime, endTime: slot.endTime, day }])
              setDays(prevSlots => [...prevSlots, { startTime: slot.startTime, endTime: slot.endTime, day }])

        }if(day?.includes('Thursday')){
              setSlotsThur(prevSlots => [...prevSlots, { startTime: slot.startTime, endTime: slot.endTime, day }])
              setDays(prevSlots => [...prevSlots, { startTime: slot.startTime, endTime: slot.endTime, day }])

        }if(day?.includes('Friday')){
              setSlotsFri(prevSlots => [...prevSlots, { startTime: slot.startTime, endTime: slot.endTime, day }])
              setDays(prevSlots => [...prevSlots, { startTime: slot.startTime, endTime: slot.endTime, day }])

        }if(day?.includes('Saturday')){
              setSlotsSat(prevSlots => [...prevSlots, { startTime: slot.startTime, endTime: slot.endTime, day }])
              setDays(prevSlots => [...prevSlots, { startTime: slot.startTime, endTime: slot.endTime, day }])

        }if(day?.includes('Sunday')){
              setSlotsSun(prevSlots => [...prevSlots, { startTime: slot.startTime, endTime: slot.endTime, day }])
              setDays(prevSlots => [...prevSlots, { startTime: slot.startTime, endTime: slot.endTime, day }])

        }

            console.log(`  Start Time: ${slot.startTime}, End Time: ${slot.endTime}`);
        });
    }
}
}
console.log(days,'days--')
  useEffect(() => {
    if (data8) {
      reset({
        activity: data8?.getCategoryById?.name || "",
        capacity: data8?.getCategoryById.capacity || "", //integer
        //1990-01-01T00:00:00.000Z
        description: data8?.getCategoryById?.description || "", //string

        address: data8?.getCategoryById?.location?.address || "", //string
        city: data8?.getCategoryById?.location?.city || "", //city
        country: data8?.getCategoryById?.location?.country || "", //
        lat: data8?.getCategoryById?.location?.lat || "", //float
        lon: data8?.getCategoryById?.location?.lon || "", //float
        term: data8?.getCategoryById?.terms,
        rules: data8?.getCategoryById?.rules,
        // availableAt: data8?.getCategoryById?.availableAt ? new Date(data8?.getCategoryById?.availableAt) : null, //date
        // minThreshold: data8?.getCategoryById?.minThreshold || "", //integer
        name: data8?.getCategoryById?.name || "", //string
        price: data8?.getCategoryById?.price || "", //
        organId: {
          label: data8?.getCategoryById?.organizer?.name,
          value: data8?.getCategoryById?.organizer?.id,
        },
        // rating: data8?.getCategoryById?.rating || "", //float
        // orgName: data8?.getCategoryById?.organizer[0]?.name || "",
        // orgNumber: data8?.getCategoryById?.organizer[0]?.phoneNumber || "",
        // orgEmail: data8?.getCategoryById?.organizer[0]?.email || "",
        //unavailableAt: data8?.getCategoryById?.unavailableAt ? new Date(data8?.getCategoryById?.unavailableAt) : null,

        // Ensure boolean value
      })
      setPreImages(data8?.getCategoryById?.pictures);
      setCategoryId(data8?.getCategoryById?.id);
      setOrganId(data8?.getCategoryById?.organizer?.id);
      setSlotId(data8?.getCategoryById?.slots?.id);
//setServices(data8?.getCategoryById?.services)

    }

  }, [data8])

  const handleViewDetails = (row) => {
    toggle1();
    setCurrentCategory(row);
  };

  const column = [
    {
      name: "s.no",
      sortable: true,
      minWidth: "172px",
      sortField: "role",
      selector: (row, ind) => ind + 1,
    },
    {
      name: "name",
      sortable: true,
      minWidth: "172px",
      sortField: "role",
      selector: (row) => row?.name,
    },
    {
      name: "Feature Image",
      sortable: true,
      Width: "auto",
      sortField: "role",
      selector: (row) => (
        <img
          className="img-fluid"
          style={{ height: "45px", objectFit: "contain" }}
          src={`${process?.env?.REACT_APP_OUT_URL}${row?.featuredImage}`}
          alt=""
        />
      ),
    },

    {
      name: "Picture",
      sortable: true,
      Width: "100%",
      sortField: "role",
      selector: (row) =>
        row?.picture?.map((item) => {
          return (
            <>
              <img
                className="img-fluid"
                style={{ height: "25px", objectFit: "contain" }}
                src={`${process?.env?.REACT_APP_OUT_URL}${item}`}
                alt=""
              />
            </>
          );
        }),
    },

    {
      name: "Actions",
      width: "300px",
      cell: (row) => (
        <div>
          {
            <Trash
              size={17}
              style={{ cursor: "pointer" }}
              onClick={() => handleDeleteActivityType(row?.id)}
            >
              Delete
            </Trash>
          }
          <Edit
            size={17}
            onClick={() => handleEditActivity(row)}
            className="ms-2"
            style={{ cursor: "pointer", marginLeft: "15px" }}
          />
          <Eye
            size={17}
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate(`/category/list/${row?.id}`, {
                state: {
                  type: "sports",
                  activityId: params?.type,
                  typeId: row?.id,
                },
              })
            }
            className="ms-2 "
          >
            View
          </Eye>
        </div>
      ),
    },
  ];

  const columnActivity = [
    {
      name: "s.no",
      sortable: true,
      minWidth: "172px",
      sortField: "role",
      selector: (row, ind) => ind + 1,
    },
    {
      name: "name",
      sortable: true,
      minWidth: "172px",
      sortField: "role",
      selector: (row) => row?.name,
    },
    {
      name: "Location",
      sortable: true,
      Width: "auto",
      sortField: "role",
      selector: (row) => row?.location?.city,
    },

    {
      name: "Picture",
      sortable: true,
      Width: "100%",
      sortField: "role",
      selector: (row) =>
        row?.pictures?.map((item) => {
          return (
            <>
              <img
                className="img-fluid"
                style={{    marginRight:'5px', height: "25px", objectFit: "contain" }}
                src={`${process?.env?.REACT_APP_OUT_URL}${item}`}
                alt=""
              />
            </>
          );
        }),
    },
    {
      name: "Actions",
      width: "300px",
      cell: (row) => (
        <div>
          {
            <Trash
              size={17}
              style={{ cursor: "pointer" }}
              onClick={() => handleDeleteCategory(row?.id)}
            >
              Delete
            </Trash>
          }
          <Edit
            size={17}
            onClick={() => handleEditCategory(row)}
            style={{ cursor: "pointer", marginLeft: "15px" }}
            className="ms-2"
          />
          <Eye
            size={17}
            style={{ backgroundColor: "transparent", cursor: "pointer" }}
            onClick={() => handleViewDetails(row)}
            className="ms-2"
          >
            View
          </Eye>
        </div>
      ),
    },
  ];

  const options = [
    { label: "Gym", value: "Gym" },
    { label: "Events", value: "Events" },
    { label: "Trainer", value: "Trainer" },
    { label: "Sports", value: "Sports" },
    { label: "Experience", value: "Experience" },
  ];
  //console.log(value,'ajsfjasfj')

  const handleReset = () => {
    reset();
    typeImagesObj = [];
    typeImagesArray = [];
    singleFileObj = [];
    singleFileArray = [];
    setSingleFile([]);
    setTypeImages([]);
    setDays([]);
    setOrImage([]);
    setServices([]);
    setService(null);
    setSlotsMon([]);
    setSlotsTue([]);
    setSlotsFri([]);
    setSlotsSat([]);
    setSlotsSun([]);
    setSlotsThur([]);
    setSlotsWed([]);
    console.log("whatsafyasf");
  };

  const { loading, error, data, refetch } = useQuery(
    location?.state?.type == "activity"
      ? GET_ACTIVITY_BY_ID
      : GET_CATEGORY_BY_ID,
    {
      variables: {
        input:
          location?.state?.type == "activity"
            ? {
                limit: 10,
                pageStart: page - 1,
                typeId: parseInt(params?.type),
              }
            : location?.state?.type == "sports"
            ? {
                activityId: parseInt(location.state?.typeId),
                //   availableAt: null,
                //   highRating: null,
                //    lat: null,
                limit: 10,
                //   lon: null,
                pageStart: page - 1,
                //   radius: null,
                typeId: parseInt(location.state?.activityId),
              }
            : {
                activityId: null,
                //  availableAt: null,
                //  highRating: null,
                // lat: 20.123,
                limit: 10,
                //   lon: 70.123,
                pageStart: page - 1,
                // radius: null,
                typeId: parseInt(params?.type),
                //limit: 10,
                // pageStart: page - 1,
                //typeId: parseInt(params?.type),
              },
      },
    }
  );
  // console.log(errors, "what is this");

  useEffect(() => {
    if (!location?.state?.type == "activity") {
      refetch();
    }
  }, [location?.state?.type]);

  console.log(parseInt(params?.type), "");

  const handleCreateActivityType = (data) => {
    if (edit) {
      console.log(ActivityTypeId?.id, "currrrrr");
      // const activityId = ;
      const payload = {
        name: data?.activity,
        activityId: parseInt(ActivityTypeId?.id, 10),
      };

      console.log(payload, "what is the data");

      UpdateActivityTypeOnSubmit(
        payload,
        updateActivity,
        typeFeatureImage ? typeFeatureImage : null,
        typeImages?.length > 0 ? typeImages : null,
        refetch,
        setModal,
        handleReset,
        remove
      );
    } else {
      //   console.log( data, "what is the data")
      const payload = {
        activity: data?.activity,
        typeId: parseInt(params?.type),
      };

      console.log(payload, "payload");
      console.log(typeFeatureImage, "lelelle");

      if (!typeFeatureImage) {
        setErrorFeatureType("Please upload Images");
        return;
      }

      if (!typeImages?.length) {
        setErrorFeatureType("Please upload Images")
        return;
      }

      console.log(typeFeatureImage, "payload");

      AddActivityTypeOnSubmit(
        payload,
        createActivity,
        typeFeatureImage,
        typeImages,
        refetch,
        setModal,
        handleReset
      );
    }
  };

  function convertToISO(dateStr) {
    const date = new Date(dateStr);

    // Convert to ISO string and adjust for time zone
    const isoString = date?.toISOString();

    return isoString;
  }

  const handleCreateActivity = (data) => {
    console.log(edit, "data-->");
    // data.preventDefault();
    const date = new Date(); // Current date and time
    const isoString = date?.toISOString();

    if (edit) {
      //   console?.log(data,'datanew')
      console.log(days, "days");
      let payload;

        payload = {
          category: {
            id: categoryId,
            name: data?.name,
            location: {
              address: data?.address,
              city: data?.city,
              country: data?.country,
              lat: data?.lat,
              lon: data?.lon,
            },
            // "active": null,
            //  availableAt: convertToISO(data?.availableAt),
            capacity: data?.capacity,
            description: data?.description,
            //  minThreshold: data?.minThreshold,
            price: data?.price,
            organizerId: data?.organId?.value,
            rules: data?.rules,
            terms: data?.term,
             services: services,
            //  rating: data?.rating,
            // unavailableAt: convertToISO(data?.unavailableAt),
          },
          //removePictures:remove,

          slot: {
            categoryId: categoryId,
            days: days,
            id: slotId,
          },

        };

      console.log(payload, "what is this");

      //   if(services?.length){
      //   payload.category.services = services
      // }

      //  if (days?.length) {
      //    payload.category.slot.days = days;
      //  }

     // console.log("hsadjasf", typeImages);

     // console.log(payload, "safafsfsa");

      UpdateCategoryOnSubmit(
        payload,
        updateCategory,
        typeImages,
        refetch,
        setModal,
        handleReset,
        OrImage,
        remove
      );
    } else {
      //  if(setDays?.length > 0  ){
      //   setSlotsError("please Add slots.")
      //   return
      //  }

      // if(services?.length){
      //         payload.services = services
      //       }else{
      //     setErrorService("Please add Service.")
      //         return
      //       }

      //       if(!typeImages?.length){

      //          setCatError("Please Upload Images.")
      //         return

      //       }

      // if (!OrImage)
      //   {
      //  setErrorOr("Please Upload Logo.");
      //   return;
      // }else{
      //    console.log(OrImage,'asfsafsaf')
      // }
      if (!days?.length) {
        setSlotsError("Please Add Slots.");
        return;
      }
      if (!services?.length) {
        setErrorService("Please add Service.");
        return;
      }

      if (!typeImages?.length) {
        setCatError("Please Upload Images.");
        return;
      }

      const payload = {
        active: true,
        activityId:
          location?.state?.type == "sports"
            ? parseInt(location?.state?.typeId)
            : null, //cricket id
        capacity: data?.capacity, //integer
        createdAt: isoString, //1990-01-01T00:00:00.000Z
        description: data?.description, //string
        location: {
          address: data?.address, //string
          city: data?.city, //city
          country: data?.country, //
          lat: data?.lat, //float
          lon: data?.lon, //float
        },
        //  availableAt: convertToISO(data?.availableAt), //date
        // minThreshold: data?.minThreshold, //integer
        name: data?.name, //string
        price: data?.price, //
        // rating: data?.rating, //float
        rules: data?.rules,
        terms: data?.term,
        services: services, //array
        typeId:
          location?.state?.type == "sports"
            ? parseInt(location?.state?.activityId)
            : parseInt(params?.type),
        // unavailableAt: convertToISO(data?.unavailableAt),

        // orgName: data?.orgName,
        // orgEmail: data?.orgEmail,
        // orgNumber: data?.orgNumber,
        days: days,
        organizerId: data?.organId?.value,
      };

      //console.log(payload,'ahsfakjshfka')

      AddActivityOnSubmit(
        payload,
        createCategory,
        typeImages,
        refetch,
        setModal,
        handleReset
      );

      console.log(payload, "data22-->");
    }
  };

  console.log(location?.state?.type, "ajsfkjasf");
  const CustomPagination = () => (
    <ReactPaginate
      nextLabel=""
      breakLabel="..."
      previousLabel=""
      pageRangeDisplayed={2}
      forcePage={page - 1}
      marginPagesDisplayed={2}
      activeClassName="active"
      pageClassName="page-item"
      breakClassName="page-item"
      nextLinkClassName="page-link"
      pageLinkClassName="page-link"
      breakLinkClassName="page-link"
      previousLinkClassName="page-link"
      nextClassName="page-item next-item"
      previousClassName="page-item prev-item"
      pageCount={Number(
        Math.ceil(
          location?.state?.type == "activity"
            ? data?.getAllActivitiesByAdmin?.totalActivities / 10
            : data?.getCategories?.totalCategories / 10
        )
      )}
      onPageChange={(page) => {
        setPage(page.selected + 1);
      }}
      containerClassName="pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-1"
    />
  );

  console.log(typeImages, "typeImagesArray");
  return (
    <>
      {location?.state?.type == "activity" ? (
        <Card className="overflow-hidden">
          <Row>
            <Col md={6}>
              <h4 className="mx-2 mt-2"> Activity Types</h4>
            </Col>

            <Col md={6}>
              <div className="px-2 pt-2 d-flex justify-content-end align-items-center">
                <button
                  onClick={() => {
                    toggle();
                    setEdit(false);
                    reset();
                    setActivityTypeId(null);
                  }}
                  className="mx-2 mt-1 btn btn-primary"
                >
                  Add New Type
                </button>
              </div>
            </Col>
          </Row>

          <div className="react-dataTable">
            <DataTable
              subHeader
              columns={column}
              data={data?.getAllActivitiesByAdmin?.activities}
              highlightOnHover
              pagination
              paginationPerPage={10}
              paginationComponent={CustomPagination}
            />
          </div>
        </Card>
      ) : (
        <Card className="overflow-hidden">
          <Row>
            <Col md={6}>
              <h4 className="mx-2 mt-2"> Activities</h4>
            </Col>

            <Col md={6}>
              <div className="px-2 pt-2 d-flex justify-content-end align-items-center">
                <button
                  onClick={ async() => {
                    toggle();
                    setEdit(false);
                   await handleReset();
                    setActivityTypeId(null);
                  }}
                  className="mx-2 mt-1 btn btn-primary"
                >
                  Add New Activities
                </button>
              </div>
            </Col>
          </Row>

          <div className="react-dataTable">
            <DataTable
              subHeader
              columns={columnActivity}
              data={data?.getCategories?.category}
              highlightOnHover
              pagination
              paginationPerPage={10}
              paginationComponent={CustomPagination}
            />
          </div>
        </Card>
      )}

      {/* <Fragment>
      <Nav pills className='mb-2'>

        <NavItem>
          <NavLink active={active === '1'} onClick={() => toggleTab('1')}>

            <span className='fw-bold'>ALL</span>
          </NavLink>
        </NavItem>
{  data &&m\

 data?.getAllActivityTypes?.map((item,index)=> {
 return(
  <>
       <NavItem>
          <NavLink active={active === `${item?.name}${index}`} onClick={() => toggleTab(`${item?.name}${index}`)}>

            <span className='fw-bold'>{item?.name}</span>
          </NavLink>
        </NavItem>
  </>
 )
 })

}
          <NavItem className='d-flex align-items-center '>
           <div onClick={()=>toggle()}  >
          <label style={{color:'#7367f0' , fontWeight:"600" , cursor:"pointer",marginLeft:'30px' }}>+ Add new Activity Type</label>
           </div>
          </NavItem>
      </Nav>

      <TabContent activeTab={active}>
        <TabPane tabId='1'>
          <ALL/>
        </TabPane>

       {  data &&
 data?.getAllActivityTypes?.map((item,index)=> {
 return(
  <>
      <TabPane tabId={`${item?.name}${index}`}>
        <Categories title={`${item?.name}`} />
        </TabPane>
  </>
 )
 })

}
      </TabContent>
    </Fragment> */}
      {location?.state?.type == "activity" ? (
        //activity
        <Modal centered isOpen={modal} toggle={toggle}>
          <ModalHeader style={{ height: "70PX" }} toggle={toggle}>
            <h5> {edit ? "Edit Activity Type" : "Add New Activity Type"}</h5>
          </ModalHeader>
          <ModalBody className={"px-2"}>
            <form onSubmit={handleSubmit(handleCreateActivityType)}>
              <Row className="mt-1">
                <Col md="12" className={"d-flex"}>
                  <div className="d-flex justify-content-start align-items-center">
                    <label
                      className=""
                      style={{ fontWeight: "bold", paddingBottom: "5px" }}
                    >
                      Type Name
                    </label>
                  </div>
                </Col>
                <Col md="12">
                  <div className="d-flex justify-content-end align-items-center">
                    <Controller
                      name="activity"
                      control={control}
                      defaultValue={""} // Ensure default value is set here
                      render={({ field }) => (
                        <Input
                          id="activity"
                          placeholder="Activity Type Name"
                          isInvalid={!!errors?.activity} // Corrected to isInvalid from invalid
                          {...field}
                        />
                      )}
                    />
                  </div>
                  {errors?.activity && (
                    <p className="mt-1 text-danger">
                      {errors?.activity?.message}
                    </p>
                  )}
                </Col>
              </Row>
              <Row className="mt-1">
                <Col md="12" className={"d-flex "}>
                  <div className="d-flex justify-content-start align-items-center">
                    <label
                      className=""
                      style={{ fontWeight: "bold", paddingBottom: "5px" }}
                    >
                      Featured Image
                    </label>
                  </div>
                </Col>


                <Col md="12">
                  <div className=" d-flex justify-content-end align-items-center">
                    <Input
                    className='mb-1'
                      type="file"
                         accept="image/jpeg, image/png, image/gif, image/webp image/webp, image/heic, image/heif"
                      onChange={(e) => {
                        setTypeFeatureImage(e?.target?.files[0]);
                        setErrorFeatureType(null);
                         setEditImage(null);
                    setImageLink(URL.createObjectURL(e.target.files[0]));
                      }}
                    />
                  </div>
                      {edit ? (
                <div className="p-0  img-block bg-gray ">
                  <img
                    style={{
                      objectFit: "contain",
                      height: "100%",
                      width: "100%",
                    }}
                    className="img-fluid"
                    src={editImage ? editImage : imageLink}
                    alt=""
                  />
                </div>
              ) : (
                imageLink &&
                <div className="p-0  img-block bg-gray ">
                  <img
                    style={{
                      objectFit: "contain",
                      height: "100%",
                      width: "100%",
                    }}
                    className="img-fluid"
                    src={imageLink}
                    alt=""
                  />
                </div>
              )}
                  {errors?.featureImage && (
                    <p className="mt-1 text-danger">
                      {errors?.featureImage?.message}
                    </p>
                  )}
                </Col>
              </Row>
              <Row>
                {edit && (
                  <>

                    <div
                      className="mt-2   "
                      style={{ flexWrap: "wrap", boxSizing: "border-box" }}
                    >
                          <label className="mb-1" style={{fontWeight:'bold'}}>Pictures</label>

                      {preImages?.map((item, index) => {
                        return (


                                <div class=""><div class="img-block bg-gray">
                    <img class="img-fluid2"
                      src={`${process.env.REACT_APP_OUT_URL}${item}`}
                       alt="..." />
                       <span class="remove_img" onClick={() => handleRemovePreImage(index)}>X</span></div>
                       </div>

                        );
                      })}
                    </div>
                  </>
                )}
                <IterateUplaod
                  error={setErrorFeatureType}
                  typeImagesObj={typeImagesObj}
                  typeImagesArray={typeImagesArray}
                  setTypeImages={setTypeImages}
                  typeImages={typeImages}
                  singleFileObj={singleFileObj}
                  singleFileArray={singleFileArray}
                  singleFile={singleFile}
                  setSingleFile={setSingleFile}
                />
              </Row>
              {errorFeatureType && (
                <p className=" text-danger">{errorFeatureType}</p>
              )}
              <Row className="">
                <Col md="12">
                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      type="submit"
                      id="btn-hammad"
                      className="btn btn-primary"
                    >
                      {edit ? "Update" : "Create New Type"}
                    </button>
                  </div>
                </Col>
              </Row>
            </form>
          </ModalBody>
        </Modal>
      ) : (
        //nonactivity
        <Modal size={"lg"} centered isOpen={modal} toggle={toggle}>
          <ModalHeader style={{ height: "70PX" }} toggle={toggle}>
            <h5> {edit ? "Edit Activity" : "Add New Activity"}</h5>
          </ModalHeader>
          <ModalBody className={"px-2"}>
            <form onSubmit={handleSubmit(handleCreateActivity)}>
              <Row className="mt-1"></Row>

              <Row className="mt-1 mb-2">
                {
                  // name
                }

                <Col md="12">
                  <Row>
                    <Col md="6" lg="6" className={""}>
                      <div className="d-flex  justify-content-start align-items-center">
                        <label
                          className=""
                          style={{ fontWeight: "bold", paddingBottom: "5px" }}
                        >
                          Activity Name
                        </label>
                      </div>
                      <div className="d-flex mb-2 justify-content-end align-items-center">
                        <Controller
                          name="name"
                          control={control}
                          defaultValue="" // Ensure default value is set here
                          render={({ field }) => (
                            <Input
                              id="name"
                              placeholder="Activity Name"
                              isInvalid={!!errors?.name} // Corrected to isInvalid from invalid
                              {...field}
                            />
                          )}
                        />
                      </div>
                      {errors?.name && (
                        <p className="mt-1 text-danger">
                          {errors?.name?.message}
                        </p>
                      )}
                    </Col>
                    {
                      // price
                    }

                    <Col md="6" lg="6" className={""}>
                      <div className="d-flex  justify-content-start align-items-center">
                        <label
                          className=""
                          style={{ fontWeight: "bold", paddingBottom: "5px" }}
                        >
                          Price
                        </label>
                      </div>
                      <div className="d-flex mb-2 justify-content-end align-items-center">
                        <Controller
                          name="price"
                          control={control}
                          defaultValue="" // Ensure default value is set here
                          render={({ field }) => (
                            <Input
                              id="price"
                              placeholder="Price"
                              isInvalid={!!errors?.price} // Corrected to isInvalid from invalid
                              {...field}
                            />
                          )}
                        />
                      </div>
                      {errors?.price && (
                        <p className="mt-1 text-danger">
                          {errors?.price?.message}
                        </p>
                      )}
                    </Col>
                    {
                      // rating
                    }
                    {/*
                    <Col md="6" lg="6" className={" "}>
                      <div className="d-flex  justify-content-start align-items-center">
                        <label
                          className=""
                          style={{ fontWeight: "bold", paddingBottom: "5px" }}
                        >
                          Rating
                        </label>
                      </div>
                      <div className="d-flex mb-2 justify-content-end align-items-center">
                        <Controller
                          name="rating"
                          control={control}
                          defaultValue="" // Ensure default value is set here
                          render={({ field }) => (
                            <Input
                              id="rating"
                              placeholder="Rating"
                              isInvalid={!!errors?.rating} // Corrected to isInvalid from invalid
                              {...field}
                            />
                          )}
                        />
                      </div>
                      {errors?.rating && (
                        <p className="mt-1 text-danger">
                          {errors?.rating?.message}
                        </p>
                      )}
                    </Col> */}
                    {
                      // Capacity
                    }
                    <Col md="6" lg="6" className={""}>
                      <div className="d-flex justify-content-start align-items-center">
                        <label
                          className=""
                          style={{ fontWeight: "bold", paddingBottom: "5px" }}
                        >
                          Capacity
                        </label>
                      </div>
                      <div className="d-flex mb-2 justify-content-end align-items-center">
                        <Controller
                          name="capacity"
                          control={control}
                          defaultValue="" // Ensure default value is set here
                          render={({ field }) => (
                            <Input
                              id="capacity"
                              placeholder="Capacity"
                              isInvalid={!!errors?.capacity} // Corrected to isInvalid from invalid
                              {...field}
                            />
                          )}
                        />
                      </div>
                      {errors?.capacity && (
                        <p className="mt-1 text-danger">
                          {errors?.capacity?.message}
                        </p>
                      )}
                    </Col>

                    {/* <Col md="6" lg="6" className={""}>
                      <div className="d-flex justify-content-start align-items-center">
                        <label
                          className=""
                          style={{ fontWeight: "bold", paddingBottom: "5px" }}
                        >
                          Available At
                        </label>
                      </div>
                      <div
                        style={{ marginRight: "2px" }}
                        className="d-flex  align-items-center"
                      >
                        <Controller
                          name="availableAt"
                          control={control}
                          render={({ field }) => (
                            <div className="w-100">
                              <DatePicker
                                wrapperClassName="datePicker"
                                selected={field.value}
                                onChange={(date) => field.onChange(date)} // Update field value
                                dateFormat="yyyy/MM/dd"
                                placeholderText="Select a date"
                              />
                            </div>
                          )}
                        />
                      </div>
                      {errors?.availableAt && (
                        <p className="mt-1 text-danger">
                          {errors?.availableAt?.message}
                        </p>
                      )}
                    </Col> */}
                    {
                      //MinThreshold
                    }
                    {/*
                    <Col md="6" lg="6" className={""}>
                      <div className="d-flex justify-content-start align-items-center">
                        <label
                          className=""
                          style={{ fontWeight: "bold", paddingBottom: "5px" }}
                        >
                          UnAvailible At
                        </label>
                      </div>
                      <div className="w-100 mb-2 d-flex align-items-center">
                        <Controller
                          name="unavailableAt"
                          control={control}
                          render={({ field }) => (
                            <div className="w-100">
                              <DatePicker
                                wrapperClassName="datePicker"
                                selected={field.value}
                                onChange={(date) => field.onChange(date)} // Update field value
                                dateFormat="yyyy/MM/dd"
                                placeholderText="Select a date"
                              />
                            </div>
                          )}
                        />
                      </div>
                      {errors?.unavailableAt && (
                        <p className="mt-1 text-danger">
                          {errors?.unavailableAt?.message}
                        </p>
                      )}
                    </Col> */}

                    {/* <Col md="6" lg="6" className={""}>
                      <div className="d-flex justify-content-start align-items-center">
                        <label
                          className=""
                          style={{ fontWeight: "bold", paddingBottom: "5px" }}
                        >
                          MinThreshold
                        </label>
                      </div>
                      <div className="d-flex mb-2 justify-content-end align-items-center">
                        <Controller
                          name="minThreshold"
                          control={control}
                          defaultValue="" // Ensure default value is set here
                          render={({ field }) => (
                            <Input
                              id="minThreshold"
                              type="text"
                              row="5"
                              placeholder="MinThreshold"
                              isInvalid={!!errors?.minThreshold} // Corrected to isInvalid from invalid
                              {...field}
                            />
                          )}
                        />
                      </div>
                      {errors?.minThreshold && (
                        <p className="mt-1 text-danger">
                          {errors?.minThreshold?.message}
                        </p>
                      )}
                    </Col> */}

                    <Col md="6" lg="6" className={""}>
                      <div className="d-flex justify-content-start align-items-center">
                        <label
                          className=""
                          style={{ fontWeight: "bold", paddingBottom: "5px" }}
                        >
                          Address
                        </label>
                      </div>
                      <div className="d-flex mb-2 justify-content-end align-items-center">
                        <Controller
                          name="address"
                          control={control}
                          defaultValue="" // Ensure default value is set here
                          render={({ field }) => (
                            <Input
                              id="address"
                              type="text"
                              row="5"
                              placeholder="Address"
                              isInvalid={!!errors?.address} // Corrected to isInvalid from invalid
                              {...field}
                            />
                          )}
                        />
                      </div>
                      {errors?.address && (
                        <p className="mt-1 text-danger">
                          {errors?.address?.message}
                        </p>
                      )}
                    </Col>
                    {
                      //Availible At
                    }

                    <Col md="6" lg="6" className={""}>
                      <div className="d-flex justify-content-start align-items-center">
                        <label
                          className=""
                          style={{ fontWeight: "bold", paddingBottom: "5px" }}
                        >
                          Country
                        </label>
                      </div>
                      <div className="d-flex mb-2 justify-content-end align-items-center">
                        <Controller
                          name="country"
                          control={control}
                          defaultValue="" // Ensure default value is set here
                          render={({ field }) => (
                            <Input
                              id="country"
                              type="text"
                              row="5"
                              placeholder="Country"
                              isInvalid={!!errors?.country} // Corrected to isInvalid from invalid
                              {...field}
                            />
                          )}
                        />
                      </div>
                      {errors?.country && (
                        <p className="mt-1 text-danger">
                          {errors?.country?.message}
                        </p>
                      )}
                    </Col>

                    <Col md="6" lg="6" className={""}>
                      <div className="d-flex justify-content-start align-items-center">
                        <label
                          className=""
                          style={{ fontWeight: "bold", paddingBottom: "5px" }}
                        >
                          City
                        </label>
                      </div>
                      <div className="d-flex mb-2 justify-content-end align-items-center">
                        <Controller
                          name="city"
                          control={control}
                          defaultValue="" // Ensure default value is set here
                          render={({ field }) => (
                            <Input
                              id="city"
                              type="text"
                              row="5"
                              placeholder="City"
                              isInvalid={!!errors?.city} // Corrected to isInvalid from invalid
                              {...field}
                            />
                          )}
                        />
                      </div>
                      {errors?.city && (
                        <p className="mt-1 text-danger">
                          {errors?.city?.message}
                        </p>
                      )}
                    </Col>

                    <Col md="6" lg="6" className={""}>
                      <div className="d-flex justify-content-start align-items-center">
                        <label
                          className=""
                          style={{ fontWeight: "bold", paddingBottom: "5px" }}
                        >
                          longitude
                        </label>
                      </div>
                      <div className="d-flex mb-2 justify-content-end align-items-center">
                        <Controller
                          name="lon"
                          control={control}
                          defaultValue="" // Ensure default value is set here
                          render={({ field }) => (
                            <Input
                              id="lon"
                              type="text"
                              row="5"
                              placeholder="longitude"
                              isInvalid={!!errors?.lon} // Corrected to isInvalid from invalid
                              {...field}
                            />
                          )}
                        />
                      </div>
                      {errors?.lon && (
                        <p className="mt-1 text-danger">
                          {errors?.lon?.message}
                        </p>
                      )}
                    </Col>

                    <Col md="6" lg="6" className={""}>
                      <div className="d-flex justify-content-start align-items-center">
                        <label
                          className=""
                          style={{ fontWeight: "bold", paddingBottom: "5px" }}
                        >
                          latitude
                        </label>
                      </div>
                      <div className="d-flex mb-2 justify-content-end align-items-center">
                        <Controller
                          name="lat"
                          control={control}
                          defaultValue="" // Ensure default value is set here
                          render={({ field }) => (
                            <Input
                              id="lat"
                              type="text"
                              row="5"
                              placeholder="latitude"
                              isInvalid={!!errors?.lat} // Corrected to isInvalid from invalid
                              {...field}
                            />
                          )}
                        />
                      </div>
                      {errors?.lat && (
                        <p className="mt-1 text-danger">
                          {errors?.lat?.message}
                        </p>
                      )}
                    </Col>

                    {/* <Col md="6" lg="6" className={""}>
                      <div className="d-flex justify-content-start align-items-center">
                        <label
                          className=""
                          style={{ fontWeight: "bold", paddingBottom: "5px" }}
                        >
                          Organizer Name
                        </label>
                      </div>
                      <div className="d-flex mb-2 justify-content-end align-items-center">
                        <Controller
                          name="orgName"
                          control={control}
                          defaultValue="" // Ensure default value is set here
                          render={({ field }) => (
                            <Input
                              id="lat"
                              type="text"
                              row="5"
                              placeholder="Organizer Name"
                              isInvalid={!!errors?.orgName} // Corrected to isInvalid from invalid
                              {...field}
                            />
                          )}
                        />
                      </div>
                      {errors?.orgName && (
                        <p className="mt-1 text-danger">
                          {errors?.orgName?.message}
                        </p>
                      )}
                    </Col> */}

                    {/* <Col md="6" lg="6" className={""}>
                      <div className="d-flex justify-content-start align-items-center">
                        <label
                          className=""
                          style={{ fontWeight: "bold", paddingBottom: "5px" }}
                        >
                          Organizer Email
                        </label>
                      </div>
                      <div className="d-flex mb-2 justify-content-end align-items-center">
                        <Controller
                          name="orgEmail"
                          control={control}
                          defaultValue="" // Ensure default value is set here
                          render={({ field }) => (
                            <Input
                              id="orgEmail"
                              type="text"

                              placeholder="Organizer Email"
                              isInvalid={!!errors?.orgEmail} // Corrected to isInvalid from invalid
                              {...field}
                            />
                          )}
                        />
                      </div>
                      {errors?.orgEmail && (
                        <p className="mt-1 text-danger">
                          {errors?.orgEmail?.message}
                        </p>
                      )}
                    </Col> */}

                    {/* <Col md="6" lg="6" className={""}>
                      <div className="d-flex justify-content-start align-items-center">
                        <label
                          className=""
                          style={{ fontWeight: "bold", paddingBottom: "5px" }}
                        >
                          Organizer PhoneNumber
                        </label>
                      </div>
                      <div className="d-flex mb-2 justify-content-end align-items-center">
                        <Controller
                          name="orgNumber"
                          control={control}
                          defaultValue="" // Ensure default value is set here
                          render={({ field }) => (
                            <Input
                              id="Orphone"
                              type="text"
                              row="5"
                              placeholder="Organizer PhoneNumber"
                              isInvalid={!!errors?.orgNumber} // Corrected to isInvalid from invalid
                              {...field}
                            />
                          )}
                        />
                      </div>
                      {errors?.Orphone && (
                        <p className="mt-1 text-danger">
                          {errors?.orgNumber?.message}
                        </p>
                      )}
                    </Col> */}

                    {/* <Col md="6" lg="6" className={""}>
                      <div className="d-flex justify-content-start align-items-center">
                        <label
                          className=""
                          style={{ fontWeight: "bold", paddingBottom: "5px" }}
                        >
                          Organizer Logo Image
                        </label>
                      </div>
                      <div className="d-flex mb-2 justify-content-end align-items-center">
                        <Controller
                          name="OrImage"
                          control={control}
                          defaultValue={null} // Ensure default value is set to null
                          render={({ field: { onChange, onBlur, ref } }) => (
                            <Input
                              type="file"
                              onChange={(e) => {
                                onChange(() => {
                                  setOrImage(e?.target?.files[0]);
                                  setErrorOr(null);
                                });
                                //hehehe
                              }}
                              onBlur={onBlur}
                              innerRef={ref}
                              isInvalid={!!errors?.OrImage}
                            />
                          )}
                        />
                      </div>
                    </Col>
                    */}

                    <Col md="6">
                      <div className="d-flex justify-content-start align-items-center">
                        <label
                          className=""
                          style={{ fontWeight: "bold", paddingBottom: "5px" }}
                        >
                          Oranigzer
                        </label>
                      </div>
                      <div className="w-100 d-flex justify-content-end align-items-center">
                        {dataOr && (
                          <Controller
                            name="organId"
                            control={control}
                            defaultValue={
                              formattedOptions?.length > 0
                                ? formattedOptions[0]
                                : null
                            } // Ensure default value is set here
                            render={({ field }) => (
                              <Select
                                {...field}
                                className="w-100"
                                id="organId"
                                options={formattedOptions}
                                placeholder="Select Type"
                                isInvalid={!!errors?.organId} // Corrected to isInvalid from invalid
                              />
                            )}
                          />
                        )}
                      </div>
                      {errors?.organId && (
                        <p className="mt-1 text-danger">
                          {errors?.organId?.message}
                        </p>
                      )}
                    </Col>

                    <Col md="6 "></Col>

                    <Col md="12 mt-2" lg="6" className={""}>
                      <div className="d-flex justify-content-start align-items-center">
                        <label
                          className=""
                          style={{ fontWeight: "bold", paddingBottom: "5px" }}
                        >
                          Rules
                        </label>
                      </div>
                      <div className="d-flex justify-content-end align-items-center">
                        <Controller
                          name="rules"
                          control={control}
                          defaultValue="" // Ensure default value is set here
                          render={({ field }) => (
                            <Input
                              id="rules"
                              type="textarea"
                              row="5"
                              placeholder="Rules"
                              isInvalid={!!errors?.rules} // Corrected to isInvalid from invalid
                              {...field}
                            />
                          )}
                        />
                      </div>
                      {errors?.rules && (
                        <p className="mt-1 text-danger">
                          {errors?.rules?.message}
                        </p>
                      )}
                    </Col>

                    <Col md="12" lg="6" className={"mt-2 "}>
                      <div className="d-flex justify-content-start align-items-center">
                        <label
                          className=""
                          style={{ fontWeight: "bold", paddingBottom: "5px" }}
                        >
                          Terms
                        </label>
                      </div>
                      <div className="d-flex justify-content-end align-items-center">
                        <Controller
                          name="term"
                          control={control}
                          defaultValue="" // Ensure default value is set here
                          render={({ field }) => (
                            <Input
                              id="term"
                              type="textarea"
                              row="5"
                              placeholder="term"
                              isInvalid={!!errors?.term} // Corrected to isInvalid from invalid
                              {...field}
                            />
                          )}
                        />
                      </div>
                      {errors?.term && (
                        <p className="mt-1 text-danger">
                          {errors?.term?.message}
                        </p>
                      )}
                    </Col>

                    <Col md="12" lg="12" className={"mt-2"}>
                      <div className="d-flex justify-content-start align-items-center">
                        <label
                          className=""
                          style={{ fontWeight: "bold", paddingBottom: "5px" }}
                        >
                          Description
                        </label>
                      </div>
                      <div className="d-flex justify-content-end align-items-center">
                        <Controller
                          name="description"
                          control={control}
                          defaultValue="" // Ensure default value is set here
                          render={({ field }) => (
                            <Input
                              id="description"
                              type="textarea"
                              row="5"
                              placeholder="Description"
                              isInvalid={!!errors?.description} // Corrected to isInvalid from invalid
                              {...field}
                            />
                          )}
                        />
                      </div>
                      {errors?.description && (
                        <p className="mt-1 text-danger">
                          {errors?.description?.message}
                        </p>
                      )}
                    </Col>
                  </Row>
                </Col>

                <Col className={"py-3"} md="6" lg="">
                  <label
                    className=""
                    style={{ fontWeight: "bold", paddingBottom: "5px" }}
                  >
                    Slots
                  </label>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        active={active === "1"}
                        onClick={() => {
                          toggleTab("1");
                        }}
                      >
                        Monday
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        active={active === "2"}
                        onClick={() => {
                          toggleTab("2");
                        }}
                      >
                        Tuesday
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        active={active === "3"}
                        onClick={() => {
                          toggleTab("3");
                        }}
                      >
                        Wednesday
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        active={active === "4"}
                        onClick={() => {
                          toggleTab("4");
                        }}
                      >
                        Thursday
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        active={active === "5"}
                        onClick={() => {
                          toggleTab("5");
                        }}
                      >
                        Friday
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        active={active === "6"}
                        onClick={() => {
                          toggleTab("6");
                        }}
                      >
                        Saturday
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        active={active === "7"}
                        onClick={() => {
                          toggleTab("7");
                        }}
                      >
                        Sunday
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <div
                    style={{
                      border: "2px solid #d9d9d9",
                      borderRadius: "10px",
                      padding: "10px 10px",
                    }}
                  >
                    <div className="d-flex justify-content-start align-items-center"></div>

                    <TabContent className="py-50" activeTab={active}>
                      <TabPane tabId="1">
                        <Row>
                          <Col md="3" lg="3" className={""}>
                            <div className="d-flex justify-content-start align-items-center">
                              <label
                                className=""
                                style={{
                                  fontWeight: "bold",
                                  paddingBottom: "5px",
                                }}
                              >
                                StartTime
                              </label>
                            </div>
                            <div className="d-flex mb-2  align-items-center">
                              <TimePicker
                                id="1"
                                key={1}
                                onChange={(newTime) => setStartTimeM(newTime)}
                                value={startTimeM}
                              />
                              {
                                //m
                              }
                            </div>
                          </Col>

                          <Col md="3" lg="3" className={""}>
                            <div className="d-flex justify-content-start align-items-center">
                              <label
                                className=""
                                style={{
                                  fontWeight: "bold",
                                  paddingBottom: "5px",
                                }}
                              >
                                EndTime
                              </label>
                            </div>
                            <div className="d-flex mb-2 align-items-center">
                              <TimePicker
                                onChange={(newTime) => setEndTimeM(newTime)}
                                value={endTimeM}
                              />
                            </div>
                          </Col>
                          <Col md="3" lg="4" className={""}>
                            <button
                              //type="submit"
                              className="btn btn-primary mt-2"
                              onClick={(event) => {
                                event.preventDefault();
                                createSlots(
                                  event,
                                  slotsMon,
                                  startTimeM,
                                  endTimeM,
                                  "Monday",
                                  setSlotsMon
                                );
                              }}
                            >
                              Create slot
                            </button>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12" className="">
                            {slotsMon?.map((item, index) => {
                              return (//currrrr
                                <>
                                  <div
                                    className="mb-1 px-2 py-1"
                                    style={{
                                      backgroundColor: "#d9d9d9",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <Row>
                                      <label
                                        className="mb-1"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Slot {index + 1}
                                      </label>

                                      <Col md={4}>
                                        <label>Start Time : </label>
                                        <label className="ms-2">
                                          {convertTo12HourFormat(
                                            item?.startTime
                                          )}
                                        </label>
                                      </Col>
                                      <Col md={4}>
                                        <label className="">End Time : </label>
                                        <label className="ms-2 ">
                                          {convertTo12HourFormat(item?.endTime)}
                                        </label>
                                      </Col>
                                      <Col md={4}>
                                       <div style={{cursor:'pointer'}} className="d-flex justify-content-between">

                                        <div>
                                         <label className="">Day : </label>
                                        <label className="ms-2 ">
                                          {item?.day}
                                        </label>
                                        </div>
                                        <Trash onClick={()=>handleDeleteSlots(item , setSlotsMon , slotsMon , index  )} size={'17'} />
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </>
                              );
                            })}
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        <Row>
                          <Col md="3" lg="3" className={""}>
                            <div className="d-flex justify-content-start align-items-center">
                              <label
                                className=""
                                style={{
                                  fontWeight: "bold",
                                  paddingBottom: "5px",
                                }}
                              >
                                StartTime
                              </label>
                            </div>
                            <div className="d-flex mb-2  align-items-center">
                              <TimePicker
                                onChange={(newtime) => setStartTimeT(newtime)}
                                value={startTimeT}
                              />
                            </div>
                          </Col>

                          <Col md="3" lg="3" className={""}>
                            <div className="d-flex justify-content-start align-items-center">
                              <label
                                className=""
                                style={{
                                  fontWeight: "bold",
                                  paddingBottom: "5px",
                                }}
                              >
                                EndTime
                              </label>
                            </div>
                            <div className="d-flex mb-2 align-items-center">
                              <TimePicker
                                onChange={(newtime) => setEndTimeT(newtime)}
                                value={endTimeT}
                              />
                            </div>
                          </Col>
                          <Col md="3" lg="4" className={""}>
                            <button
                              className="btn btn-primary mt-2"
                              onClick={(event) => {
                                event.preventDefault();
                                createSlots(
                                  event,
                                  slotsTue,
                                  startTimeT,
                                  endTimeT,
                                  "Tuesday",
                                  setSlotsTue
                                );
                              }}
                            >
                              Create slot
                            </button>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12" className="">
                            {slotsTue?.map((item, index) => {
                              return (
                                <>
                                  <div
                                    className="mb-1 px-2 py-1"
                                    style={{
                                      backgroundColor: "#d9d9d9",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <Row>
                                      <label
                                        className="mb-1"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Slot {index + 1}
                                      </label>

                                      <Col md={4}>
                                        <label>Start Time : </label>
                                        <label className="ms-2">
                                          {convertTo12HourFormat(
                                            item?.startTime
                                          )}
                                        </label>
                                      </Col>
                                      <Col md={4}>
                                        <label className="">End Time : </label>
                                        <label className="ms-2 ">
                                          {convertTo12HourFormat(item?.endTime)}
                                        </label>
                                      </Col>
                                      <Col md={4}>

                                       <div style={{cursor:'pointer'}} className="d-flex justify-content-between">

                                        <div>
                                         <label className="">Day : </label>
                                        <label className="ms-2 ">
                                          {item?.day}
                                        </label>
                                        </div>
                                        <Trash onClick={()=>handleDeleteSlots(item , setSlotsTue , slotsTue , index  )} size={'17'} />
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </>
                              );
                            })}
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="3">
                        <Row>
                          <Col md="3" lg="3" className={""}>
                            <div className="d-flex justify-content-start align-items-center">
                              <label
                                className=""
                                style={{
                                  fontWeight: "bold",
                                  paddingBottom: "5px",
                                }}
                              >
                                StartTime
                              </label>
                            </div>
                            <div className="d-flex mb-2  align-items-center">
                              <TimePicker
                                onChange={(item) => setStartTime(item)}
                                value={startTime}
                              />
                            </div>
                          </Col>

                          <Col md="3" lg="3" className={""}>
                            <div className="d-flex justify-content-start align-items-center">
                              <label
                                className=""
                                style={{
                                  fontWeight: "bold",
                                  paddingBottom: "5px",
                                }}
                              >
                                EndTime
                              </label>
                            </div>
                            <div className="d-flex mb-2 align-items-center">
                              <TimePicker
                                onChange={(item) => setEndTime(item)}
                                value={endTime}
                              />
                            </div>
                          </Col>
                          <Col md="3" lg="4" className={""}>
                            <button
                              className="btn btn-primary mt-2"
                              onClick={(event) => {
                                event.preventDefault();
                                createSlots(
                                  event,
                                  slotsWed,
                                  startTime,
                                  endTime,
                                  "Wednesday",
                                  setSlotsWed
                                );
                              }}
                            >
                              Create slot
                            </button>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12" className="">
                            {slotsWed?.map((item, index) => {
                              return (
                                <>
                                  <div
                                    className="mb-1 px-2 py-1"
                                    style={{
                                      backgroundColor: "#d9d9d9",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <Row>
                                      <label
                                        className="mb-1"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Slot {index + 1}
                                      </label>

                                      <Col md={4}>
                                        <label>Start Time : </label>
                                        <label className="ms-2">
                                          {convertTo12HourFormat(
                                            item?.startTime
                                          )}
                                        </label>
                                      </Col>
                                      <Col md={4}>
                                        <label className="">End Time : </label>
                                        <label className="ms-2 ">
                                          {convertTo12HourFormat(item?.endTime)}
                                        </label>
                                      </Col>
                                      <Col md={4}>
                                         <div style={{cursor:'pointer'}} className="d-flex justify-content-between">

                                        <div>
                                         <label className="">Day : </label>
                                        <label className="ms-2 ">
                                          {item?.day}
                                        </label>
                                        </div>
                                        <Trash onClick={()=>handleDeleteSlots(item , setSlotsWed , slotsWed , index  )} size={'17'} />
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </>
                              );
                            })}
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="4">
                        <Row>
                          <Col md="3" lg="3" className={""}>
                            <div className="d-flex justify-content-start align-items-center">
                              <label
                                className=""
                                style={{
                                  fontWeight: "bold",
                                  paddingBottom: "5px",
                                }}
                              >
                                StartTime
                              </label>
                            </div>
                            <div className="d-flex mb-2  align-items-center">
                              <TimePicker
                                onChange={(item) => setStartTimeN(item)}
                                value={startTimeN}
                              />
                            </div>
                          </Col>

                          <Col md="3" lg="3" className={""}>
                            <div className="d-flex justify-content-start align-items-center">
                              <label
                                className=""
                                style={{
                                  fontWeight: "bold",
                                  paddingBottom: "5px",
                                }}
                              >
                                EndTime
                              </label>
                            </div>
                            <div className="d-flex mb-2 align-items-center">
                              <TimePicker
                                onChange={(item) => setEndTimeN(item)}
                                value={endTimeN}
                              />
                            </div>
                          </Col>
                          <Col md="3" lg="4" className={""}>
                            <button
                              className="btn btn-primary mt-2"
                              onClick={(event) => {
                                event.preventDefault();
                                createSlots(
                                  event,
                                  slotsThur,
                                  startTimeN,
                                  endTimeN,
                                  "Thursday",
                                  setSlotsThur
                                );
                              }}
                            >
                              Create slot
                            </button>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12" className="">
                            {slotsThur?.map((item, index) => {
                              return (
                                <>
                                  <div
                                    className="mb-1 px-2 py-1"
                                    style={{
                                      backgroundColor: "#d9d9d9",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <Row>
                                      <label
                                        className="mb-1"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Slot {index + 1}
                                      </label>

                                      <Col md={4}>
                                        <label>Start Time : </label>
                                        <label className="ms-2">
                                          {convertTo12HourFormat(
                                            item?.startTime
                                          )}
                                        </label>
                                      </Col>
                                      <Col md={4}>
                                        <label className="">End Time : </label>
                                        <label className="ms-2 ">
                                          {convertTo12HourFormat(item?.endTime)}
                                        </label>
                                      </Col>
                                      <Col md={4}>
                                        <div style={{cursor:'pointer'}} className="d-flex justify-content-between">

                                        <div>
                                         <label className="">Day : </label>
                                        <label className="ms-2 ">
                                          {item?.day}
                                        </label>
                                        </div>
                                        <Trash onClick={()=>handleDeleteSlots(item , setSlotsThur , slotsThur , index  )} size={'17'} />
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </>
                              );
                            })}
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="5">
                        <Row>
                          <Col md="3" lg="3" className={""}>
                            <div className="d-flex justify-content-start align-items-center">
                              <label
                                className=""
                                style={{
                                  fontWeight: "bold",
                                  paddingBottom: "5px",
                                }}
                              >
                                StartTime
                              </label>
                            </div>
                            <div className="d-flex mb-2  align-items-center">
                              <TimePicker
                                onChange={(item) => setStartTimeR(item)}
                                value={startTimeR}
                              />
                            </div>
                          </Col>

                          <Col md="3" lg="3" className={""}>
                            <div className="d-flex justify-content-start align-items-center">
                              <label
                                className=""
                                style={{
                                  fontWeight: "bold",
                                  paddingBottom: "5px",
                                }}
                              >
                                EndTime
                              </label>
                            </div>
                            <div className="d-flex mb-2 align-items-center">
                              <TimePicker
                                onChange={(item) => setEndTimeR(item)}
                                value={endTimeR}
                              />
                            </div>
                          </Col>
                          <Col md="3" lg="4" className={""}>
                            <button
                              className="btn btn-primary mt-2"
                              onClick={(event) => {
                                event.preventDefault();
                                createSlots(
                                  event,
                                  slotsFri,
                                  startTimeR,
                                  endTimeR,
                                  "Friday",
                                  setSlotsFri
                                );
                              }}
                            >
                              Create slot
                            </button>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12" className="">
                            {slotsFri?.map((item, index) => {
                              return (
                                <>
                                  <div
                                    className="mb-1 px-2 py-1"
                                    style={{
                                      backgroundColor: "#d9d9d9",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <Row>
                                      <label
                                        className="mb-1"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Slot {index + 1}
                                      </label>

                                      <Col md={4}>
                                        <label>Start Time : </label>
                                        <label className="ms-2">
                                          {convertTo12HourFormat(
                                            item?.startTime
                                          )}
                                        </label>
                                      </Col>
                                      <Col md={4}>
                                        <label className="">End Time : </label>
                                        <label className="ms-2 ">
                                          {convertTo12HourFormat(item?.endTime)}
                                        </label>
                                      </Col>
                                      <Col md={4}>
                                         <div style={{cursor:'pointer'}} className="d-flex justify-content-between">

                                        <div>
                                         <label className="">Day : </label>
                                        <label className="ms-2 ">
                                          {item?.day}
                                        </label>
                                        </div>
                                        <Trash onClick={()=>handleDeleteSlots(item , setSlotsFri , slotsFri , index  )} size={'17'} />
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </>
                              );
                            })}
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="6">
                        <Row>
                          <Col md="3" lg="3" className={""}>
                            <div className="d-flex justify-content-start align-items-center">
                              <label
                                className=""
                                style={{
                                  fontWeight: "bold",
                                  paddingBottom: "5px",
                                }}
                              >
                                StartTime
                              </label>
                            </div>
                            <div className="d-flex mb-2  align-items-center">
                              <TimePicker
                                id="6"
                                onChange={(item) => setStartTimeF(item)}
                                value={startTimeF}
                              />
                            </div>
                          </Col>

                          <Col md="3" lg="3" className={""}>
                            <div className="d-flex justify-content-start align-items-center">
                              <label
                                className=""
                                style={{
                                  fontWeight: "bold",
                                  paddingBottom: "5px",
                                }}
                              >
                                EndTime
                              </label>
                            </div>
                            <div className="d-flex mb-2 align-items-center">
                              <TimePicker
                                onChange={(item) => setEndTimeF(item)}
                                value={endTimeF}
                              />
                            </div>
                          </Col>
                          <Col md="3" lg="4" className={""}>
                            <button
                              className="btn btn-primary mt-2"
                              onClick={(event) => {
                                event.preventDefault();
                                createSlots(
                                  event,
                                  slotsSat,
                                  startTimeF,
                                  endTimeF,
                                  "Saturday",
                                  setSlotsSat
                                );
                              }}
                            >
                              Create slot
                            </button>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12" className="">
                            {slotsSat?.map((item, index) => {
                              return (
                                <>
                                  <div
                                    className="mb-1 px-2 py-1"
                                    style={{
                                      backgroundColor: "#d9d9d9",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <Row>
                                      <label
                                        className="mb-1"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Slot {index + 1}
                                      </label>

                                      <Col md={4}>
                                        <label>Start Time : </label>
                                        <label className="ms-2">
                                          {convertTo12HourFormat(
                                            item?.startTime
                                          )}
                                        </label>
                                      </Col>
                                      <Col md={4}>
                                        <label className="">End Time : </label>
                                        <label className="ms-2 ">
                                          {convertTo12HourFormat(item?.endTime)}
                                        </label>
                                      </Col>
                                      <Col md={4}>
                                        <div style={{cursor:'pointer'}} className="d-flex justify-content-between">

                                        <div>
                                         <label className="">Day : </label>
                                        <label className="ms-2 ">
                                          {item?.day}
                                        </label>
                                        </div>
                                        <Trash onClick={()=>handleDeleteSlots(item , setSlotsSat , slotsSat , index  )} size={'17'} />
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </>
                              );
                            })}
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="7">
                        <Row>
                          <Col md="3" lg="3" className={""}>
                            <div className="d-flex justify-content-start align-items-center">
                              <label
                                className=""
                                style={{
                                  fontWeight: "bold",
                                  paddingBottom: "5px",
                                }}
                              >
                                StartTime
                              </label>
                            </div>
                            <div className="d-flex mb-2  align-items-center">
                              <TimePicker
                                id="7"
                                onChange={(item) => setStartTimeS(item)}
                                value={startTimeS}
                              />
                            </div>
                          </Col>

                          <Col md="3" lg="3" className={""}>
                            <div className="d-flex justify-content-start align-items-center">
                              <label
                                className=""
                                style={{
                                  fontWeight: "bold",
                                  paddingBottom: "5px",
                                }}
                              >
                                EndTime
                              </label>
                            </div>
                            <div className="d-flex mb-2 align-items-center">
                              <TimePicker
                                id="7"
                                onChange={(item) => setEndTimeS(item)}
                                value={endTimeS}
                              />
                            </div>
                          </Col>
                          <Col md="3" lg="4" className={""}>
                            <button
                              className="btn btn-primary mt-2"
                              onClick={(event) =>
                                createSlots(
                                  event,
                                  slotsSun,
                                  startTimeS,
                                  endTimeS,
                                  "Sunday"
                                )
                              }
                            >
                              Create slot
                            </button>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12" className="">
                            {slotsSun?.map((item, index) => {
                              return (
                                <>
                                  <div
                                    className="mb-1 px-2 py-1"
                                    style={{
                                      backgroundColor: "#d9d9d9",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <Row>
                                      <label
                                        className="mb-1"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Slot {index + 1}
                                      </label>

                                      <Col md={4}>
                                        <label>Start Time : </label>
                                        <label className="ms-2">
                                          {convertTo12HourFormat(
                                            item?.startTime
                                          )}
                                        </label>
                                      </Col>
                                      <Col md={4}>
                                        <label className="">End Time : </label>
                                        <label className="ms-2 ">
                                          {convertTo12HourFormat(item?.endTime)}
                                        </label>
                                      </Col>
                                      <Col md={4}>
                                       <div style={{cursor:'pointer'}} className="d-flex justify-content-between">

                                        <div>
                                         <label className="">Day : </label>
                                        <label className="ms-2 ">
                                          {item?.day}
                                        </label>
                                        </div>
                                        <Trash onClick={()=>handleDeleteSlots(item , setSlotsSun , slotsSun , index  )} size={'17'} />
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </>
                              );
                            })}
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </div>
                  {slotsError && (
                    <p className="mt-1 text-danger">{slotsError}</p>
                  )}
                </Col>
              </Row>

              <Row>
                {
                  //current
                }
                <Col md="6" lg="6" className={""}>
                  <div className="d-flex justify-content-start align-items-center">
                    <label
                      className=""
                      style={{ fontWeight: "bold", paddingBottom: "5px" }}
                    >
                      Services
                    </label>
                  </div>
                  <div className="d-flex justify-content-end align-items-center">
                    <Input
                      id="service"
                      type="text"

                      onChange={(e) => {
                        // Update the field value
                        setService(e.target.value);
                        console.log(e.target.value, "hehehe"); // Log the value
                      }}
                      placeholder="Services"
                      isInvalid={!!errors?.service} // Ensure the name matches for validation
                    />

                    <button
                      disabled={!service?.length}
                      className="mx-1 btn btn-primary "
                      onClick={(e) => handleAddServices(e)}
                    >
                      Add
                    </button>
                  </div>
                  <div className="mt-2">
                    {services?.map((item, index) => {
                      return (
                        <>
                          <div className="d-flex mx-1">
                            <p>{item}</p>
                            <IoMdCloseCircle
                              style={{ marginLeft: "10px", cursor: "pointer" }}
                              onClick={() => handleDeleteService(index)}
                              color="red"
                              size={22}
                            />
                          </div>
                        </>
                      );
                    })}
                  </div>
                  {errorService && (
                    <p className="mt-1 text-danger">{errorService}</p>
                  )}
                </Col>
                <Col md="6" lg="6">
                  {edit && (
                    <>

                    <label style={{fontWeight:'bold'}}>Pictures</label>
                      <div
                        className=" d-flex  "
                        style={{ flexWrap: "wrap", boxSizing: "border-box" }}
                      >
                        {preImages?.map((item, index) => {
                          return (

                             <div class=""><div class="img-block bg-gray">
                    <img class="img-fluid2"
                      src={`${process.env.REACT_APP_OUT_URL}${item}`}
                       alt="..." />
                       <span class="remove_img" onClick={() => handleRemovePreImage(index)}>X</span></div>
                       </div>

                          );
                        })}
                      </div>
                    </>
                  )}
                  <IterateUploadActivity
                    error={setCatError}
                    typeImagesObj={typeImagesObj}
                    typeImagesArray={typeImagesArray}
                    setTypeImages={setTypeImages}
                    typeImages={typeImages}
                    singleFileObj={singleFileObj}
                    singleFileArray={singleFileArray}
                    singleFile={singleFile}
                    setSingleFile={setSingleFile}
                  />
                  {catError && <p className="mt-1 text-danger">{catError}</p>}
                </Col>
              </Row>
              <div className="d-flex justify-content-center align-items-center">
                <button
                  type="submit"
                  className="btn btn-primary"

                >
                  {edit ? "Update" : "Create New Activity"}
                </button>
              </div>

              <Row className="">
                <Col md="12"></Col>
              </Row>
            </form>
          </ModalBody>
        </Modal>
      )}

      {
        //this is for details
      }
      <Modal size={"lg"} centered isOpen={detail} toggle={toggle1}>
        <ModalHeader style={{ height: "70PX" }} toggle={toggle1}>
          <h5> {"Activity Details"}</h5>
        </ModalHeader>
        <ModalBody className={"px-2"}>
          <Row className="mt-1"></Row>

          <Row className="mt-1 mb-2">
            {
              // name
            }

            <Col md="12">
              <Row>
                <Col md="6" lg="6" className={""}>
                  <div className="d-flex  justify-content-between align-items-center">
                    <label className="" style={{ fontWeight: "bold" }}>
                      Activity Name :
                    </label>
                    <p className="mt-1">{currentCategory?.name}</p>
                  </div>
                </Col>
                {
                  // price
                }

                <Col md="6" lg="6" className={""}>
                  <div className="d-flex  justify-content-between align-items-center">
                    <label className="" style={{ fontWeight: "bold" }}>
                      Price :
                    </label>
                    <p className="mt-1">{currentCategory?.price}</p>
                  </div>
                </Col>
                {
                  // rating
                }

                <Col md="6" lg="6" className={" "}>
                  <div className="d-flex  justify-content-between align-items-center">
                    <label className="" style={{ fontWeight: "bold" }}>
                      Rating :
                    </label>
                    <p className="mt-1">{currentCategory?.rating}</p>
                  </div>
                </Col>
                {
                  // Capacity
                }
                <Col md="6" lg="6" className={""}>
                  <div className="d-flex  justify-content-between align-items-center">
                    <label className="" style={{ fontWeight: "bold" }}>
                      Capacity :
                    </label>
                    <p className="mt-1">{currentCategory?.capacity}</p>
                  </div>
                </Col>

                <Col md="6" lg="6" className={""}>
                  <div className="d-flex  justify-content-between align-items-center">
                    <label className="" style={{ fontWeight: "bold" }}>
                      Terms :
                    </label>
                    <p className="mt-1">{currentCategory?.terms}</p>
                  </div>
                </Col>
                {
                  //MinThreshold
                }

                <Col md="6" lg="6" className={""}>
                  <div className="d-flex  justify-content-between align-items-center">
                    <label className="" style={{ fontWeight: "bold" }}>
                      Rules :
                    </label>
                    <p className="mt-1">{currentCategory?.rules}</p>
                  </div>
                </Col>

                <Col md="6" lg="6" className={""}>
                  <div className="d-flex  justify-content-between align-items-center">
                    <label className="" style={{ fontWeight: "bold" }}>
                      Address :
                    </label>
                    <p style={{ maxWidth: "250px" }} className="mt-1 ">
                      {currentCategory?.location?.address}
                    </p>
                  </div>
                </Col>

                {
                  //Availible At
                }

                <Col md="6" lg="6" className={""}>
                  <div className="d-flex  justify-content-between align-items-center">
                    <label className="" style={{ fontWeight: "bold" }}>
                      Country :
                    </label>
                    <p className="mt-1">{currentCategory?.location?.country}</p>
                  </div>
                </Col>

                <Col md="6" lg="6" className={""}>
                  <div className="d-flex  justify-content-between align-items-center">
                    <label className="" style={{ fontWeight: "bold" }}>
                      City :
                    </label>
                    <p className="mt-1">{currentCategory?.location?.city}</p>
                  </div>
                </Col>

                <Col md="6" lg="6" className={""}>
                  <div className="d-flex  justify-content-between align-items-center">
                    <label className="" style={{ fontWeight: "bold" }}>
                      Longitude :
                    </label>
                    <p className="mt-1">{currentCategory?.location?.lon}</p>
                  </div>
                </Col>

                <Col md="6" lg="6" className={""}>
                  <div className="d-flex  justify-content-between align-items-center">
                    <label className="" style={{ fontWeight: "bold" }}>
                      Latitude :
                    </label>
                    <p className="mt-1">{currentCategory?.location?.lat}</p>
                  </div>
                </Col>

                <Col md="6" lg="6" className={" "}>
                  <div className="d-flex  justify-content-between align-items-center">
                    <label className="" style={{ fontWeight: "bold" }}>
                      Oraganizer Name :
                    </label>
                    <p className="mt-1">{currentCategory?.organizer?.name}</p>
                  </div>
                </Col>

                {
                  //description
                }

                <Col md="6" lg="6" className={""}>
                  <div className="d-flex  justify-content-between align-items-center">
                    <label className="" style={{ fontWeight: "bold" }}>
                      Description :
                    </label>
                    <p className="mt-1">{currentCategory?.description}</p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            {
              //current
            }
            <Col md="6" lg="6" className={""}>
              <div className="d-flex justify-content-between ">
                <label
                  className=""
                  style={{ fontWeight: "bold", paddingBottom: "5px" }}
                >
                  Services
                </label>

                <div className="">
                  {currentCategory?.services?.map((item, index) => {
                    return (
                      <>
                        <div className="d-flex mx-1">
                          <p>{item}</p>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </Col>

            <Col md="6" lg="6">
              <label
                className=""
                style={{ fontWeight: "bold", paddingBottom: "15 px" }}
              >
                Pictures
              </label>
              <div className="d-flex flex-wrap gap-1">
                {currentCategory?.pictures?.map((item) => {
                  return (
                    <>
                      <img
                        className="img-fluid"
                        style={{  height: "65px", objectFit: "contain" }}
                        src={`${process?.env?.REACT_APP_OUT_URL}${item}`}
                        alt=""
                      />
                    </>
                  );
                })}
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}
