/*eslint-disable */
import React, { Fragment, useEffect, useState } from 'react'
import { Archive, FileText, Lock, MoreVertical, Trash2, User , Edit, Trash, Eye } from 'react-feather'

import { Card, CardBody, CardHeader, NavLink, Nav, NavItem, TabContent, TabPane, Button, Modal, ModalHeader, Col, ModalBody, Row, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, Input } from 'reactstrap'

import { GET_ACTIVITY_TYPES, GET_ALL_ORGANIZERS } from '../../graphql/query/query'
import { useMutation, useQuery } from '@apollo/client'
import Avatar from '../components/avatar'
import DataTable from 'react-data-table-component'
import { CREATE_ACTIVITY_TYPE, CREATE_ORGANIZER, DELETE_ACTIVITY_TYPE, DELETE_ORGANIZER, UPDATE_ACTIVITY_TYPE, UPDATE_ORGANIZER } from '../../graphql/mutation'
import { CreateActivityOnSubmit, CreateOrganizerOnSubmit, DeleteActivityOnSubmit, DeleteOrganizerOnSubmit, UpdateActivityOnSubmit, UpdateOrganizerOnSubmit } from '../../graphql/api-callings'
import { Controller, set, useForm } from 'react-hook-form'
import { addActivity, AddOrganizer } from '../../validation'
import { yupResolver } from '@hookform/resolvers/yup'
import Select from "react-select";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";

import { useNavigate } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
export default function Organizer() {

const [modal, setModal] = useState(false);
const [detail, setDetail] = useState(false);
   const [active, setActive] = useState('1')
     const [edit, setEdit] = useState(false)
     const [page , setPage] = useState(1)
     const [organId , setOrganId] = useState(null);
     const [option , setOption ] = useState([])
     const [currentDetails , setCurrentDetails ] = useState(null)
        const [image, setImage] = useState(null);
         const [editImage, setEditImage] = useState(null);
        const [imageError , setImageError] = useState(null)
        const [imageLink, setImageLink] = useState(null);


     const navigate = useNavigate()

   const [createOrganizer, { loading: loading1, error: errror1, data: data1 }] =
     useMutation(CREATE_ORGANIZER);
   const [deleteOrganizer, { loading: loading2, error: errror2, data: data2 }] =
     useMutation(DELETE_ORGANIZER);

   const [updateOrganizer, { loading: loading3, error: errror3, data: data3 }] =
     useMutation(UPDATE_ORGANIZER);

  const toggleTab = tab => {
    if (active !== tab) {
      setActive(tab)
    }
  }

  const {
    control,
    setValue,
    setError,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AddOrganizer),
  });



  const toggle = () => setModal(!modal);

   const toggleDetail = () => setDetail(!detail);

const handleDeleteActivity = (id) => {
 // console.log(id,'id')
 const payload = {
   id: parseInt(id),
 };
  DeleteOrganizerOnSubmit(payload, deleteOrganizer, refetch);
}
 const handleEditActivity = (row) => {
   setModal(true);
   setEdit(true);
   reset({
     name: row?.name || "",
     phone: row?.phoneNumber,
     email: row?.email, // Ensure boolean value
   });
   setOrganId(row?.id);
   setEditImage(`${process?.env?.REACT_APP_OUT_URL}${row?.logo}`);
   setImageLink(null)
 }

 const handleViewActivity = (row) => {

  setCurrentDetails(row);
   toggleDetail()
 }
const column = [
  {
    name: "s.no",
    sortable: true,
    minWidth: "172px",
    sortField: "role",
    selector: (row, ind) => ind + 1,
  },
  {
    name: "name",
    sortable: true,
    minWidth: "172px",
    sortField: "role",
    selector: (row) => row?.name,
  },
  {
    name: "Email",
    sortable: true,
    minWidth: "172px",
    sortField: "role",
    selector: (row) => row?.email?.toString(),
  },
  {
    name: "Phone",
    sortable: true,
    minWidth: "172px",
    sortField: "role",
    selector: (row) => row?.phoneNumber?.toString(),
  },
    // },
  // {
  //   name: "Logo",
  //   sortable: true,
  //   minWidth: "172px",
  //   sortField: "role",
  //   selector: (row) => (
  //     <img
  //       style={{ width: "50px", objectFit: "contain" ,
  //       height:'47px' }}
  //       src={`${process.env.REACT_APP_OUT_URL}${row?.logo}`}
  //       alt=""
  //     />
  //   ),
  // },
  {
    name: "Actions",
    width: "300px",
    cell: (row) => (
      <div>
        <Trash
          size={17}
          onClick={() => handleDeleteActivity(row?.id)}
          className="mx-2"
          style={{ cursor : "pointer" }}
        >
          Delete
        </Trash>

        <Edit
          size={17}
          onClick={() => handleEditActivity(row)}
          style={{ cursor : "pointer" }}
        />

        <Eye
          size={17}
          style={{ backgroundColor: "transparent", cursor: "pointer" }}
          onClick={() => handleViewActivity(row)}
          className="ms-2 "
        >
          View
        </Eye>
      </div>
    ),
  },
];

const options = [
  { label: "Gym", value: "Gym" },
  { label: "Events", value: "Events" },
  { label: "Trainer", value: "Trainer" },
  { label: "Sports", value: "Sports" },
  { label: "Experience", value: "Experience" },
]

const { loading, error, data, refetch } = useQuery(GET_ALL_ORGANIZERS, {
  variables: {
    input: {
      limit: 10,
      pageStart: page - 1,
    },
  },
});
  // console.log(errors, "what is this");
  const CustomPagination = () => (
    <ReactPaginate
      nextLabel=""
      breakLabel="..."
      previousLabel=""
      pageRangeDisplayed={2}
      forcePage={page - 1}
      marginPagesDisplayed={2}
      activeClassName="active"
      pageClassName="page-item"
      breakClassName="page-item"
      nextLinkClassName="page-link"
      pageLinkClassName="page-link"
      breakLinkClassName="page-link"
      previousLinkClassName="page-link"
      nextClassName="page-item next-item"
      previousClassName="page-item prev-item"
      pageCount={Number(
        Math.ceil(data?.getAllOrganizers?.totalOrganizers / 10)
      )}
      onPageChange={(page) => {
        setPage(page.selected + 1);
      }}
      containerClassName="pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-1"
    />
  );

   const handleCreateActivity = ( data ) => {

    if(edit ){
      const payload = {
        id:organId,
        name: data?.name,
        email: data?.email,
        phoneNumber: data?.phone,
      };
//console.log(payload, "what is the data");


UpdateOrganizerOnSubmit(
        payload,
        image,
        updateOrganizer,
        refetch,
        setModal
      )

    } else{
       console.log( data, "what is the data");
   console.log(image,'hello')

   if(!image){
         setImageError("Logo Image Required.")
         return
       }

       const payload = {
         name: data?.name,
         email: data?.email,
         phoneNumber: data?.phone,
        };
        console.log(payload,'Hammad image.')
         CreateOrganizerOnSubmit(
           payload,
           image,
           createOrganizer,
           refetch,
           setModal
         );
       }

    }


   const formattedOptions = data?.getAllActivityTypes?.map((item) => ({
     value: item?.id,
     label: item?.name,
   }))

  return (
    <>
      <Card className="overflow-hidden">
        <Row>
          <Col md={6}>
            <h4 className="mx-2 mt-2"> Organizers </h4>
          </Col>

          <Col md={6}>
            <div className="px-2 pt-2 d-flex justify-content-end align-items-center">
              <button
                onClick={() => {
                  toggle();
                  setEdit(false);
                  reset({
                    name: "",
                    phone: "", // Ensure boolean value
                    email: "", // Ensure boolean value
                  });
                  setOrganId(null);
                }}
                className="mx-2 mt-1 btn btn-primary"
              >
                Add New Organizer
              </button>
            </div>
          </Col>
        </Row>

        <div className="react-dataTable">
          <DataTable
            subHeader
            columns={column}
            data={data?.getAllOrganizers?.organizers}
            highlightOnHover
            pagination
            //paginationServer
            //paginationTotalRows={100}
            paginationPerPage={10}
            //  CustomPagination={CustomPagination}
            // paginationComponentOptions={{
            //     noRowsPerPage: true
            // }}
            // onChangePage={page => setPage(page)}
            paginationComponent={CustomPagination}
          />
        </div>
      </Card>

      {/* <Fragment>
      <Nav pills className='mb-2'>

        <NavItem>
          <NavLink active={active === '1'} onClick={() => toggleTab('1')}>

            <span className='fw-bold'>ALL</span>
          </NavLink>
        </NavItem>
{  data &&m\

 data?.getAllActivityTypes?.map((item,index)=> {
 return(
  <>
       <NavItem>
          <NavLink active={active === `${item?.name}${index}`} onClick={() => toggleTab(`${item?.name}${index}`)}>

            <span className='fw-bold'>{item?.name}</span>
          </NavLink>
        </NavItem>
  </>
 )
 })

}
          <NavItem className='d-flex align-items-center '>
           <div onClick={()=>toggle()}  >
          <label style={{color:'#7367f0' , fontWeight:"600" , cursor:"pointer",marginLeft:'30px' }}>+ Add new Activity Type</label>
           </div>
          </NavItem>
      </Nav>

      <TabContent activeTab={active}>
        <TabPane tabId='1'>
          <ALL/>
        </TabPane>

       {  data &&
 data?.getAllActivityTypes?.map((item,index)=> {
 return(
  <>
      <TabPane tabId={`${item?.name}${index}`}>
        <Categories title={`${item?.name}`} />
        </TabPane>
  </>
 )
 })

}
      </TabContent>
    </Fragment> */}

      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader style={{ height: "70PX" }} toggle={toggle}>
          <h5> {edit ? "Edit Category Type" : "Add Category Type"}</h5>
        </ModalHeader>
        <ModalBody className={"px-2"}>
          <form onSubmit={handleSubmit(handleCreateActivity)}>
            <Row className="mt-1">
              <Col md="12" className={"d-flex "}>
                <div className="d-flex justify-content-start align-items-center">
                  <label
                    className=""
                    style={{
                      marginBottom: "2PX",
                      fontWeight: "bold",
                      paddingBottom: "5px",
                    }}
                  >
                    Oraganizer Name
                  </label>
                </div>
              </Col>
              <Col md="12">
                <div className="w-100 d-flex justify-content-end align-items-center">
                  <Controller
                    name="name"
                    control={control}
                    defaultValue="" // Ensure default value is set here
                    render={({ field }) => (
                      <Input
                        id="name"
                        placeholder="Organizer Name"
                        isInvalid={!!errors?.name} // Corrected to isInvalid from invalid
                        {...field}
                      />
                    )}
                  />
                </div>
                {errors?.name && (
                  <p className="mt-1 text-danger">{errors?.name?.message}</p>
                )}
              </Col>
            </Row>

            <Row className="mt-1">
              <Col md="12" className={"d-flex "}>
                <div className="d-flex justify-content-start align-items-center">
                  <label
                    className=""
                    style={{
                      marginBottom: "2PX",
                      fontWeight: "bold",
                      paddingBottom: "5px",
                    }}
                  >
                    Email
                  </label>
                </div>
              </Col>
              <Col md="12">
                <div className="d-flex justify-content-end align-items-center">
                  <Controller
                    name="email"
                    control={control}
                    defaultValue="" // Ensure default value is set here
                    render={({ field }) => (
                      <Input
                        id="email"
                        placeholder="Organizer Email"
                        isInvalid={!!errors?.email} // Corrected to isInvalid from invalid
                        {...field}
                      />
                    )}
                  />
                </div>
                {errors?.email && (
                  <p className="mt-1 text-danger">{errors?.email?.message}</p>
                )}
              </Col>
            </Row>

            <Row className={"mt-2"}>
              <Col md="12" className={"d-flex "}>
                <div className="d-flex justify-content-start align-items-center">
                  <label
                    className=""
                    style={{ marginBottom: "2PX", fontWeight: "bold" }}
                  >
                    PhoneNumber
                  </label>
                </div>
              </Col>
              <Col md="12">
                <div className="d-flex align-items-center">
                  <Controller
                    id="phone"
                    name="phone"
                    control={control}
                    // Ensure default value is set here
                    render={({ field }) => (
                      <Input
                        id="phone"
                        type="text"
                        placeholder="phone Number"
                        {...field}
                      />
                    )}
                  />
                </div>
                {errors?.phone && (
                  <p className="text-danger">{errors?.phone?.message}</p>
                )}
              </Col>

              <Col md="12" className={"d-flex mt-2 "}>
                <div className="d-flex justify-content-start align-items-center">
                  <label
                    className=""
                    style={{ marginBottom: "2PX", fontWeight: "bold" }}
                  >
                    Logo
                  </label>
                </div>
              </Col>

              <div className="mb-1 d-flex align-items-center">
                <Input
                  id="file"
                  type="file"
                  accept="image/jpeg, image/png, image/gif, image/webp image/webp, image/heic, image/heif"
                  onChange={(e) => {
                    setImageError(null);
                    setImage(e.target.files[0]);
                    setEditImage(null);
                    setImageLink(URL.createObjectURL(e.target.files[0]));
                  }}
                />
              </div>
              {edit ? (
                <div className="p-0 ms-1 img-block bg-gray ">
                  <img
                    style={{
                      objectFit: "contain",
                      height: "100%",
                      width: "100%",
                    }}
                    className="img-fluid"
                    src={editImage ? editImage : imageLink}
                    alt=""
                  />
                </div>
              ) : (
                imageLink && (
                  <div className="p-0 ms-1 img-block bg-gray ">
                    <img
                      style={{
                        objectFit: "contain",
                        height: "100%",
                        width: "100%",
                      }}
                      className="img-fluid"
                      src={imageLink}
                      alt=""
                    />
                  </div>
                )
              )}
              {imageError && <p className="mt-1 text-danger">{imageError}</p>}
              <Col md="9" className={"mt-2"}></Col>
            </Row>

            <Row className="mt-2">
              <Col md="12">
                <div className="d-flex justify-content-center align-items-center">
                  <button
                    type="submit"
                    id="btn-hammad"
                    className="btn btn-primary"
                  >
                    {edit ? "Update" : "Create Organizer"}
                  </button>
                </div>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>

      <Modal centered isOpen={detail} toggle={toggleDetail}>
        <ModalHeader style={{ height: "70px" }} toggle={toggleDetail}>
          <h5> {"Organizer Details"} </h5>
        </ModalHeader>
        <ModalBody className={"px-2"}>
          <Row className={"mt-2"}>
            <Col md="6" className={"d-flex mt-2 "}>
              <div className="d-flex justify-content-start align-items-center">
                <label className="" style={{ fontWeight: "bold" }}>
                  Logo
                </label>
              </div>
            </Col>

            <Col md="6" className={"d-flex mt-2 "}>
              <div className="d-flex justify-content-start align-items-center">
                <img
                  style={{ width: "150px", objectFit: "contain" }}
                  src={`${process.env.REACT_APP_OUT_URL}${currentDetails?.logo} `}
                  alt=""
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6" className={"d-flex "}>
              <div className="d-flex justify-content-start align-items-center">
                <label
                  className=""
                  style={{ fontWeight: "bold", paddingBottom: "5px" }}
                >
                  Oraganizer Name
                </label>
              </div>
            </Col>
            <Col md="6">
              <div className="d-flex justify-content-start align-items-center">
                <label
                  className=""
                  style={{ fontWeight: "bold", paddingBottom: "5px" }}
                >
                  {currentDetails?.name}
                </label>
              </div>
            </Col>
          </Row>

          <Row className="mt-1">
            <Col md="6" className={"d-flex "}>
              <div className="d-flex justify-content-start align-items-center">
                <label
                  className=""
                  style={{ fontWeight: "bold", paddingBottom: "5px" }}
                >
                  Email
                </label>
              </div>
            </Col>
            <Col md="6">
              <div className="d-flex justify-content-start align-items-center">
                <label
                  className=""
                  style={{ fontWeight: "bold", paddingBottom: "5px" }}
                >
                  {currentDetails?.email}
                </label>
              </div>
            </Col>
          </Row>

          <Row className={"mt-2"}>
            <Col md="6" className={"d-flex "}>
              <div className="d-flex justify-content-start align-items-center">
                <label className="" style={{ fontWeight: "bold" }}>
                  PhoneNumber
                </label>
              </div>
            </Col>
            <Col md="6">
              <div className="d-flex justify-content-start align-items-center">
                <label className="" style={{ fontWeight: "bold" }}>
                  {currentDetails?.phoneNumber}
                </label>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );

}
