/*eslint-disable */

import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Activites from "../../views/Activites";
import ActivityType from "../../views/ActivitesTypes";



const activitiesRoutes = [
  {
    path: "/category/list",
    element: <Activites />,
  },
  {
    path: "category/list/:type",
    element: <ActivityType />,
  }

];

export default activitiesRoutes;
